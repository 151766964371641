import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIsGameProxy } from "../../hooks";
import { getString } from "../../utilities";
import { getWithExpiry, setWithExpiry } from "../../utilities/localStorage";
import { routeCodes } from "../../config/routes";
import X from "../../../assets/svg/x.svg";
import ChocChipCookie from "../../../assets/img/chocChipCookie.png";
import { mpModalShown } from "../../utilities/mixpanel";

export default function CookieBanner() {
  const isGameProxy = useIsGameProxy();
  const cookieBannerKey = "abcya_cb";
  const isCookie = getWithExpiry(cookieBannerKey);
  const [cookieBannerHide, setCookieBannerHide] = useState(isCookie);

  const modalShownEventData = {
    modalType: "confirm-cookie-banner",
    triggerType: "page load",
    header: getString("cookieBanner.title"),
    primaryCta: "none",
    secondaryCta: "none",
    tertiaryCta: "none",
    isDismissible: true,
  };

  const rudy = document.getElementById("root");
  if (cookieBannerHide) {
    rudy.classList.remove("cookie");
  } else {
    rudy.classList.add("cookie");
  }

  const cbSetWithExpiry = () => {
    setWithExpiry(cookieBannerKey, "1", 604800000); // 1 week
  };

  if (cookieBannerHide || isGameProxy) {
    return null;
  }

  return (
    <div id="cookiebanner" className="wrapper cookiebanner">
      <div className="container cookiebanner-container">
        <Link to={`${routeCodes.GAMES}${getString("cookieBanner.shortname")}`}>
          <img
            src={ChocChipCookie}
            width="119"
            height="65"
            alt={getString("cookieBanner.title")}
            className="cookiebanner-cookie"
          />
        </Link>

        <div className="cookiebanner-text">
          <span>{getString("cookieBanner.message", { html: true })}</span>
        </div>

        <a
          className="cookiebanner-close"
          onClick={() => {
            cbSetWithExpiry();
            // should now find cookie and close banner
            setCookieBannerHide(true);
            // Mixpanel event
            mpModalShown({
              ...modalShownEventData,
              interaction: "dismissed",
            });
          }}
        >
          <X className="cookiebanner-close-x" />
        </a>
      </div>
    </div>
  );
}
