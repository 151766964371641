import React, { useContext, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { mobile } from "../../utilities/detection";
import { AdContext } from "../../context/adContext";
import AdFreeLink from "./adFreeLink";
import { useWidthSize } from "../../hooks";
import { adData } from "../../config";
import { adsSelector } from "../../redux/slices/loginSlice";

export default function Sky160600({ isLeft = false }) {
  const previousPathname = useRef(null);
  const adDiv = useRef(null);
  const skyShow = useWidthSize(["adsLG", "xl", "xxl"]);
  const { skyLeftDiv, skyRightDiv, skyLeftCreated, skyRightCreated } = useContext(AdContext);
  const ads = useSelector(adsSelector);
  const location = useLocation();

  const adId = isLeft ? adData.divIds.sky.left : adData.divIds.sky.right;

  const handleAdDiv = useCallback((node) => {
    if (node) {
      adDiv.current = isLeft ? skyLeftDiv : skyRightDiv;
      node.appendChild(isLeft ? skyLeftDiv : skyRightDiv);
      if (isLeft) {
        if (!skyLeftCreated.current) skyLeftCreated.current = true;
      } else {
        if (!skyRightCreated.current) skyRightCreated.current = true;
      }
    }
  }, []);

  if (ads && !mobile && skyShow) {
    // only shown if screen is wider than "adsLg(1180px)"
    //  see config breakpoints

    return (
      <div data-testid="sky-ad" className="a-skyscraper">
        <div id={`${adId}-container`} className="a-skyscraper-container" ref={handleAdDiv} />
        <div className="a-cta">
          <AdFreeLink isSky={true} />
        </div>
      </div>
    );
  }

  return null;
}

Sky160600.propTypes = {
  isLeft: PropTypes.bool,
};
