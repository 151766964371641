import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "urql";
import { metaDefault } from "../../config/meta";
import { UPDATE_PASSWORD_MUTATION } from "../../graphql/mutations";
import {
  EMAIL_CHAR_LIMIT,
  getLangCode,
  language,
  PASSWORD_CHAR_LIMIT,
  PASSWORD_CHAR_MINIMUM,
  recaptchaSiteKey,
  TEXT_FIELD_CHAR_LIMIT,
} from "../../config";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import ErrorMessage from "../global/errorMessage";
import TextViewHook from "../global/textViewHook";
import LoadingAnim from "../global/loadingAnim";
import { getString, handlePostRequest } from "../../utilities";
import { getMaxLengthValidator, getMinLengthValidator, validateEmail } from "../../utilities/forms/rules";
import { modalToggle } from "../../redux/slices/loginSlice";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function Password() {
  const langCode = getLangCode();
  const action = "Update_Password_Form_Submission";
  const [success, setSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const dispatch = useDispatch();

  const getVariables = () => {
    const formValues = getValues();

    if (formValues?.email) {
      formValues.email = formValues.email.trim().toLowerCase();
    }

    return formValues;
  };

  const onPwUpdateCompleted = (data) => {
    const { updatePassword } = data;
    if (updatePassword && updatePassword.success) {
      setSuccess(updatePassword.success);
    }
  };

  const [result, updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);
  const { fetching: mutating } = result;

  const onCompleted = ({ reCaptchaVerify }) => {
    if (reCaptchaVerify) {
      if (reCaptchaVerify.success) {
        // verified
        // execute password update mutation
        const variables = getVariables();

        if (variables) {
          updatePassword(variables).then((result) =>
            handlePostRequest(result, onPwUpdateCompleted, (error) => setSubmitErrors({ message: error.message })),
          );
        }
      } else {
        // not verified
        setSubmitErrors({ message: reCaptchaVerify.message });
      }
    }
  };

  const onError = (error) => {
    console.log(errors.message);
    setSubmitErrors({ message: error.message });
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onCompleted,
    onError,
  );
  const loading = mutating || recaptchaLoading;

  const renderSuccessMessage = () => {
    if (success) {
      return (
        <>
          <p>{getString("password.caption.1")}</p>

          <button type="button" className="button-flat-color pt-green" onClick={() => dispatch(modalToggle())}>
            {getString("navigation.login.1")}
          </button>
        </>
      );
    }

    return null;
  };

  const emailString = getString(getLangCode() === language.la ? "forms.email.2" : "forms.email.0");

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("password.metaTitle"), noCrawl: true })}

      <div className="password">
        <div className="wrapper padded">
          <h1>{getString("password.title")}</h1>
          <div className="container-small">{renderSuccessMessage()}</div>
        </div>
        <OptionallyDisplayed doDisplay={!success}>
          <div className="wrapper padded">
            <div className="container-small">
              <form
                onSubmit={handleSubmit(() => {
                  if (!loading) {
                    window.grecaptcha.enterprise.ready(async () => {
                      const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

                      if (token) {
                        recaptchaVerify({ token, action, verify: false, siteKey: recaptchaSiteKey });
                      }
                    });
                  }
                })}
              >
                <FormProvider register={register}>
                  <OptionallyDisplayed doDisplay={!!submitErrors}>
                    <ErrorMessage error={submitErrors} />
                  </OptionallyDisplayed>

                  <div className="cta">{emailString}</div>

                  <TextViewHook
                    name="email"
                    errors={errors}
                    placeholder={emailString}
                    rules={{
                      required: getString("forms.errorMessages.isRequired.0", {
                        replace: [getString(getLangCode() === language.la ? "forms.email.6" : "forms.email.2")],
                      }),
                      validate: (value) =>
                        validateEmail(
                          value,
                          getString(getLangCode() === language.la ? "forms.email.6" : "forms.email.0"),
                        ),
                      maxLength: getMaxLengthValidator(getString("forms.email.3"), EMAIL_CHAR_LIMIT),
                    }}
                  />

                  <div className="cta">{getString("forms.code.0")}</div>

                  <div className="cta-caption">{getString("password.codecta")}</div>

                  <TextViewHook
                    name="code"
                    type="code"
                    errors={errors}
                    placeholder={getString("forms.code.1")}
                    rules={{
                      required: getString(
                        getLangCode() === language.la
                          ? "forms.errorMessages.isRequired.1"
                          : "forms.errorMessages.isRequired.0",
                        {
                          replace: [getString(getLangCode() === language.la ? "forms.code.2" : "forms.code.0")],
                        },
                      ),
                      maxLength: getMaxLengthValidator(getString("forms.code.0"), TEXT_FIELD_CHAR_LIMIT),
                    }}
                  />

                  <div className="cta">{getString("forms.password.0")}</div>

                  <div className="cta-caption">{getString("password.caption.0")}</div>

                  <TextViewHook
                    name="password"
                    type="password"
                    errors={errors}
                    placeholder={getString("forms.newPassword.0")}
                    onChange={() => trigger("passwordVerify")}
                    rules={{
                      required: getString("forms.errorMessages.isRequired.0", {
                        replace: [
                          getString(getLangCode() === language.la ? "forms.newPassword.3" : "forms.newPassword.0"),
                        ],
                      }),
                      maxLength: getMaxLengthValidator(
                        getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
                        PASSWORD_CHAR_LIMIT,
                      ),
                      minLength: getMinLengthValidator(
                        getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
                        PASSWORD_CHAR_MINIMUM,
                      ),
                    }}
                  />

                  <TextViewHook
                    name="passwordVerify"
                    type="password"
                    errors={errors}
                    placeholder={getString("forms.newPasswordVerify")}
                    rules={{
                      validate: (value) =>
                        getValues().password === value ||
                        getString("forms.errorMessages.mustMatch.0", {
                          replace: [getString("forms.newPassword.1")],
                        }),
                    }}
                  />

                  <button type="submit" disabled={loading} className="button-flat-color pt-green">
                    {getString("update.0")}
                  </button>

                  <div className="form-terms" data-testid="formTerms">
                    {getString("recaptcha", { html: true })}
                  </div>
                </FormProvider>
              </form>

              {loading && (
                <LoadingAnim position="absolute">
                  <h4>{getString("password.loading")}</h4>
                </LoadingAnim>
              )}
            </div>
          </div>
        </OptionallyDisplayed>
      </div>
      <div className="wrapper header-shadow" />
    </>
  );
}
