import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { mobile } from "../../utilities/detection";
import { sortedArrayByItem } from "../../utilities";
import MiniBanner from "./miniBanner";
import { horizontalScroll } from "../../utilities/scroll";
import ListArrow from "./listArrow";
import { routeCodes } from "../../config/routes";
import { contentTypes } from "../../config";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { renderDesktopSignupModal } from "../../utilities/printables";

export default function List({
  title,
  className,
  platform,
  items,
  sort,
  hasNoScroll = false,
  hasNoScrollReset = false,
}) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [sortedItems, setSortedItems] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(false);
  const [display, setDisplay] = useState(
    !platform || platform === "BOTH" || (mobile && platform === "MOBILE") || (!mobile && platform === "DESKTOP"),
  );

  const location = useLocation();
  const lmc = useRef(null);
  const observer = useRef(null);

  const resetArrows = () => {
    if (lmc.current && !hasNoScroll) {
      const buffer = 2;

      const lmcScrollPos = lmc.current.scrollLeft;
      const lmcScrollWidth = lmc.current.scrollWidth;
      const lmcWidth = lmc.current.offsetWidth;
      const xOffset = lmcScrollWidth - lmcScrollPos;

      if (lmcScrollPos > 0) {
        setScrollLeft(true);
      } else {
        setScrollLeft(false);
      }
      if (xOffset <= lmcWidth + buffer && xOffset >= lmcWidth - buffer) {
        setScrollRight(false);
      } else {
        setScrollRight(true);
      }
    }
  };

  const renderTabs = () => {
    if (lmc.current && !hasNoScroll && title) {
      const tabArray = [];

      const lmcScrollPos = lmc.current.scrollLeft;
      const lmcScrollWidth = lmc.current.scrollWidth;
      const lmcWidth = lmc.current.offsetWidth;
      const percentScrolled = (lmcScrollPos + lmcWidth / 2) / lmcScrollWidth;
      const tabsLength = Math.floor(lmcScrollWidth / lmcWidth);
      const tabsID = Math.floor(percentScrolled * tabsLength);

      if (tabsLength > 1) {
        // no need if there is only one
        for (let i = 0; i < tabsLength; i++) {
          const tabStyle = i === tabsID ? " active" : "";
          tabArray.push(<div className={`tab${tabStyle}`} key={`tab${i}`} />);

          if (i === tabsLength - 1 && tabs !== tabArray) {
            setTabs(tabArray);
          }
        }
      } else {
        // if there are 1 or less set to empty so nothing renders
        setTabs([]);
      }
    }
  };

  const arrowsAndTabs = () => {
    resetArrows();
    renderTabs();
  };

  const renderTitle = () => {
    if (title) {
      return (
        <div className="list-title-tabs">
          <div className="list-title">{title}</div>
          <div className="list-tabs">{tabs}</div>
        </div>
      );
    }

    return null;
  };

  const renderItems = () => {
    return sortedItems.map((item, index) => {
      const itemKey = `mb${index}`;
      let clickHandler = item.path;

      if (item.clickHandler) {
        // if clickHandler is specifically sent use that
        clickHandler = item.clickHandler;
      } else {
        // if it's not set type defaults
        if (item.type === contentTypes.game) {
          clickHandler = `${routeCodes.GAMES}${item.path}`;
        }
        if (item.type === contentTypes.printable) {
          clickHandler = isLoggedIn ? `${routeCodes.PRINTABLES}${item.path}` : renderDesktopSignupModal;
        }
      }

      return (
        <MiniBanner
          key={itemKey}
          type={item.type}
          title={item.title}
          path={item.path}
          image={item.image}
          fallbackImage={item.fallbackImage}
          grades={item.grades}
          isMobile={item.mobile}
          style={item.style}
          isSearchable={item.searchable}
          clickHandler={clickHandler}
          hasNoScroll={hasNoScroll || false}
        />
      );
    });
  };

  const lmcCallback = function (mutationsList) {
    mutationsList.forEach((mutation) => {
      if (mutation.type === "childList") {
        // A child node has been added or removed
        arrowsAndTabs();
      }
    });
  };

  const handleLmc = useCallback((node) => {
    if (node) {
      lmc.current = node;

      lmc.current.addEventListener("scroll", arrowsAndTabs);

      // create observer if one doesn't exist
      if (!observer?.current) {
        const config = { attributes: true, childList: true, subtree: true };

        observer.current = new MutationObserver(lmcCallback);
        observer.current.observe(lmc.current, config);
      }
    }
  }, []);

  useEffect(() => {
    // items = array of objects
    // item object should be sent in this format for MiniBanner:
    // {type: game, title: My Title, path: my_path_etc, image: URL, grades: [-1, 0], order: 0, mobile: true, searchable: true}
    if (items?.length > 0) {
      if (sort) {
        setSortedItems(sortedArrayByItem(items, sort));
      } else {
        setSortedItems(items);
      }
    } else {
      setSortedItems([]);
    }
  }, [items]);

  useEffect(() => {
    const nextDisplay =
      !platform || platform === "BOTH" || (mobile && platform === "MOBILE") || (!mobile && platform === "DESKTOP");
    setDisplay(nextDisplay);
  }, [platform]);

  useEffect(() => {
    window.addEventListener("resize", arrowsAndTabs);

    return () => {
      // clean up listeners and observer
      window.removeEventListener("resize", arrowsAndTabs);

      if (observer?.current) {
        observer.current.disconnect();
      }

      if (lmc.current) {
        lmc.current.removeEventListener("scroll", arrowsAndTabs);
      }
    };
  }, []);

  useEffect(() => {
    if (lmc.current && !hasNoScrollReset && lmc.current.scrollLeft !== 0) {
      // reset scrollLeft location to 0 if coming from list already scrolled
      lmc.current.scrollLeft = 0;
    }
  }, [location]);

  if (sortedItems?.length > 0 && display) {
    const rightArrowStyle = scrollRight ? " active" : "";
    const leftArrowStyle = scrollLeft ? " active" : "";
    const listStyle = className ? ` ${className}` : "";
    const lmbcStyle = hasNoScroll ? " lmbc-noscroll" : "";

    /* if (!title) {
      lmbcStyle += " no-title";
    } */

    return (
      <div className={`list${listStyle}`}>
        <div className="container list-container" data-testid="list">
          {renderTitle()}
          <div className={`list-minibanner-container${lmbcStyle}`} ref={handleLmc}>
            {renderItems()}
          </div>

          <div className="list-arrow-container left">
            <div className="list-arrow-padding">
              <div className={`list-arrow-gradient left ${leftArrowStyle}`} />
            </div>
            <ListArrow
              className={`list-arrow left outline ${leftArrowStyle}`}
              clickHandler={() => {
                horizontalScroll(lmc.current, -(lmc.current.offsetWidth / 2));
              }}
            />
          </div>

          <div className="list-arrow-container right">
            <div className="list-arrow-padding">
              <div className={`list-arrow-gradient right ${rightArrowStyle}`} />
            </div>
            <ListArrow
              className={`list-arrow right outline ${rightArrowStyle}`}
              clickHandler={() => {
                horizontalScroll(lmc.current, lmc.current.offsetWidth / 2);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
}

List.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  platform: PropTypes.string,
  items: PropTypes.array.isRequired,
  sort: PropTypes.string,
  hasNoScroll: PropTypes.bool,
  hasNoScrollReset: PropTypes.bool,
};
