import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { osVersion, osName, browserVersion, browserName, mobileVendor, mobileModel } from "react-device-detect";
import { isMobileApple, isKindle } from "../../utilities/detection";
import Modal from "./modal";
import { getString } from "../../utilities";
import fuzzCop from "../../../assets/img/fuzzCopAds.svg";
import smoothControler from "../../../assets/img/smoothController.svg";
import downloadPlane from "../../../assets/img/downloadPlane.svg";
import { getLangCode, variousURLs } from "../../config";
import { gtmGAEvent } from "../../utilities/gtm";
import { mpModalShown } from "../../utilities/mixpanel";
export default function MobileAppDownloadModal({ doShow, setShow }) {
  const location = useLocation();

  const modalShownEventData = {
    modalType: "app download",
    triggerType: "page load",
    header: getString("mobileAppDownload.title"),
    primaryCta: getString("mobileAppDownload.continue.app"),
    secondaryCta: getString("mobileAppDownload.continue.browser"),
    tertiaryCta: "none",
    isDismissible: true,
  };

  useEffect(() => {
    if (doShow) {
      const eventObject = {
        eventCategory: "Mobile app download modal",
        eventAction: "modal-shown",
        eventLabel: location.pathname,
        eventValue: {
          osVersion,
          osName,
          browserVersion,
          browserName,
          mobileVendor,
          mobileModel,
        },
      };

      gtmGAEvent(eventObject);
    }
  }, [doShow]);

  const fuzzCopArt = {
    en: fuzzCop,
    la: fuzzCop,
  };

  const renderDeviceBenefit = () => {
    if (isMobileApple) {
      return (
        <div className="benefit">
          <img
            width="188.85"
            height="185.62"
            src={downloadPlane}
            alt={getString("mobileAppDownload.download")}
            className="benefit-art"
          />
          <div className="benefit-caption">{getString("mobileAppDownload.download")}</div>
        </div>
      );
    }

    return (
      <div className="benefit">
        <img
          width="196.19"
          height="167.4"
          src={smoothControler}
          alt={getString("mobileAppDownload.smooth")}
          className="benefit-art"
        />
        <div className="benefit-caption">{getString("mobileAppDownload.smooth")}</div>
      </div>
    );
  };

  return (
    <Modal
      doShow={doShow}
      closeOnEscape={() => {
        setShow(false);
      }}
    >
      <div className="mobile-app-download-modal-container">
        <div className="modal-content-header">{getString("mobileAppDownload.title")}</div>

        <div className="benefits-container">
          <div className="benefit">
            <img
              width="215.56"
              height="190.53"
              src={fuzzCopArt[getLangCode()]}
              alt={getString("mobileAppDownload.noAds")}
              className="benefit-art"
            />
            <div className="benefit-caption">{getString("mobileAppDownload.noAds")}</div>
          </div>

          {renderDeviceBenefit()}
        </div>

        <div className="modal-content-footer">
          <button
            type="button"
            className="button-flat-color pt-green"
            onClick={() => {
              // fire gtm event
              // navigate to app store/page
              const { IOS, ANDROID, AMAZON } = variousURLs.nativeDownload;

              let nativeUrl = ANDROID;

              if (isMobileApple) {
                nativeUrl = IOS;
              } else if (isKindle()) {
                nativeUrl = AMAZON;
              }

              setShow(false);

              gtmGAEvent({
                eventCategory: "Mobile app download modal",
                eventAction: "cta-button-click",
                eventLabel: location.pathname,
                eventValue: nativeUrl,
              });

              // Mixpanel event
              mpModalShown({
                ...modalShownEventData,
                interaction: "primary cta",
              });

              window.open(nativeUrl, "_blank", "noopener,noreferrer");
            }}
          >
            {getString("mobileAppDownload.continue.app")}
          </button>

          <div className="continue-link">
            <a
              onClick={() => {
                setShow(false);

                // Mixpanel event
                mpModalShown({
                  ...modalShownEventData,
                  interaction: "secondary cta",
                });
              }}
            >
              {getString("mobileAppDownload.continue.browser")}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

MobileAppDownloadModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
