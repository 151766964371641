import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { gtmJoinClick } from "../../utilities/gtm";
import { getString } from "../../utilities";
import { getLangCode, language } from "../../config";
import { mpSubCTASelect } from "../../utilities/mixpanel";
import { mpModalShown } from "../../utilities/mixpanel";

export default function AdFreeLink({ isSky = false }) {
  const location = useLocation();
  const adStringPath = getLangCode() !== language.default && isSky ? "ads.adSky" : "ads.ad";
  const modalShownEventData = {
    modalType: "advertisement",
    triggerType: "page load",
    header: "none",
    primaryCta: getString("ads.go"),
    secondaryCta: "none",
    tertiaryCta: "none",
    isDismissible: true,
  };

  return (
    <>
      {getString(adStringPath)}
      <a
        href={routeCodes.SUBSCRIBE}
        onClick={() => {
          gtmJoinClick(location.pathname, "advertisement-link");
          mpSubCTASelect("go ad free", "advertisement-container", getString("ads.go"));
          // Mixpanel event
          mpModalShown({
            ...modalShownEventData,
            interaction: "primary cta",
          });
        }}
      >
        {getString("ads.go")}
      </a>
    </>
  );
}

AdFreeLink.propTypes = {
  isSky: PropTypes.bool,
};
