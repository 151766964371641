import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { mobile } from "../../utilities/detection";
import { metaDefault } from "../../config/meta";
import MobileFree from "./mobileFree";
import HomeNav from "./homeNav";
import HomeLists from "./homeLists.jsx";
import { routeCodes } from "../../config/routes";
import { isLoggedInSelector, modalToggle } from "../../redux/slices/loginSlice";
import { getString, logout } from "../../utilities";
import { getParamValue } from "../../config/index.js";
import { publicPath } from "../../config/routeCodes.js";
import { getLangCode, language } from "../../config";

export default function Home({ shouldLogin }) {
  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  const handleLogin = () => {
    const accessName = getParamValue("name", { location });
    const accessCode = getParamValue("code", { location });
    let redirectPath = getParamValue("path", { location });

    redirectPath = redirectPath ? `${publicPath}${redirectPath}` : routeCodes.HOME;

    if (!isLoggedIn) {
      dispatch(modalToggle({ path: redirectPath }));
    } else if (accessName || accessCode) {
      logout();
    } else {
      navigate(redirectPath, { replace: true });
    }
  };

  useEffect(() => {
    if (shouldLogin) {
      handleLogin();
    }
  }, []);

  const mobileStyle = mobile ? " mobile" : "";

  // If on the login page of the Spanish Site, we don't use the default title. Instead, use a specific one.
  const metaTitle =
    getLangCode() === language.la && location.pathname === routeCodes.LOGIN ? getString("login.metaTitle") : "";

  return (
    <>
      {metaDefault({ path: location.pathname, noCrawl: shouldLogin && !!location.search, title: metaTitle })}

      <div className={`home${mobileStyle}`}>
        <MobileFree />

        <HomeNav />

        <HomeLists />
      </div>
    </>
  );
}

Home.propTypes = {
  shouldLogin: PropTypes.bool,
};
