import React from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import { EMAIL_CHAR_LIMIT, TEXT_FIELD_CHAR_LIMIT } from "../../config";
import { getString } from "../../utilities";
import { getMaxLengthValidator, validateWhitespace } from "../../utilities/forms/rules";
import ErrorMessage from "../global/errorMessage";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import TextViewHook from "../global/textViewHook";
import { childrenSelector } from "../../redux/slices/loginSlice";
import { mpModalShown } from "../../utilities/mixpanel";

export default function LoginFields({ error, errors, loginSubmit, submitErrors, modalShownEventData }) {
  const { handleSubmit } = useFormContext();
  const children = useSelector(childrenSelector);

  return (
    <form onSubmit={handleSubmit(loginSubmit)}>
      {error && <ErrorMessage error={error} />}

      <OptionallyDisplayed doDisplay={!!submitErrors}>
        <ErrorMessage error={submitErrors} />
      </OptionallyDisplayed>

      <p>{children || getString("login.caption")}</p>

      <TextViewHook
        rules={{
          maxLength: getMaxLengthValidator(getString("forms.email.1"), EMAIL_CHAR_LIMIT),
          validate: (value) =>
            validateWhitespace(
              value,
              getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.email.1")] }),
            ),
        }}
        errors={errors}
        name="email"
        placeholder={getString("forms.email.1")}
        label={getString("forms.email.1")}
      />

      <TextViewHook
        rules={{
          maxLength: getMaxLengthValidator(getString("forms.password.1"), TEXT_FIELD_CHAR_LIMIT),
          validate: (value) =>
            validateWhitespace(
              value,
              getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.password.1")] }),
            ),
        }}
        errors={errors}
        name="password"
        type="password"
        placeholder={getString("forms.password.1")}
        label={getString("forms.password.1")}
      />
      <button
        type="submit"
        className="button-flat-color pt-green"
        onClick={() => {
          if (modalShownEventData) {
            mpModalShown({
              ...modalShownEventData,
              interaction: "primary cta",
            });
          }
        }}
      >
        {getString("navigation.login.1")}
      </button>
    </form>
  );
}

LoginFields.propTypes = {
  error: PropTypes.object,
  errors: PropTypes.object.isRequired,
  loginSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.array,
  modalShownEventData: PropTypes.object,
};
