import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";

import { getString, handlePostRequest } from "../../utilities";
import { userRoles, publicFavLimit } from "../../config";
import { REMOVE_FAVORITE_GAME } from "../../graphql/mutations";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import FavGame from "./favGame";
import { routeCodes } from "../../config/routes";
import Arrow from "../../../assets/svg/arrowRight.svg";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { gamesSelector, roleSelector, setGames } from "../../redux/slices/loginSlice";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { mpSubCTASelect } from "../../utilities/mixpanel";

export default function FavoriteGames({ isUpdating, setUpdating }) {
  const data = useSelector(dataSelector);
  const loadingGames = useSelector(loadingSelector);
  const dispatch = useDispatch();
  const loginGames = useSelector(gamesSelector);
  const role = useSelector(roleSelector);
  const navigate = useNavigate();

  const onCompleted = (data) => {
    const { removeFavoriteGame } = data;
    setUpdating(false);
    dispatch(setGames({ games: removeFavoriteGame }));
    try {
      window.localStorage.setItem("games", JSON.stringify(removeFavoriteGame));
    } catch (e) {}
  };

  const onError = () => {
    setUpdating(false);
  };

  const [result, updateUser] = useMutation(REMOVE_FAVORITE_GAME);
  const { fetching: apiLoading, error } = result;

  const gameRemoveHandler = async (shortname) => {
    if (!isUpdating) {
      const variables = await { shortname };

      setUpdating(true);
      updateUser(variables).then((result) => handlePostRequest(result, onCompleted, onError));
    }
  };

  const renderFavGames = () => {
    if (loadingGames || apiLoading) {
      return <LoadingAnim />;
    }

    const favGames = loginGames.map((game) => game.shortname);
    const renderGames = data.games.filter((game) =>
      favGames.includes(game.localeShortname ? game.localeShortname : game.shortname),
    );

    const isFree = role === userRoles.free;
    const freeMax = loginGames.length >= publicFavLimit;

    const games = renderGames.reduce((gameArray, game, index) => {
      if (!isFree || (isFree && index < publicFavLimit)) {
        gameArray.push(
          <FavGame key={`key${game.id}`} game={game} isUpdating={isUpdating} gameRemoveHandler={gameRemoveHandler} />,
        );
      }

      return gameArray;
    }, []);

    if (isFree && freeMax) {
      // how many phantom game minibanners do you want?
      const phantomCount = 2;

      for (let i = 0; i < phantomCount; i++) {
        games.push(<FavGame key={`phantom${i}`} />);
      }
    }

    return games;
  };

  const renderInstructions = (isStudent) => {
    const isFree = role === userRoles.free;
    const freeMax = loginGames.length >= publicFavLimit;

    const instructionsText =
      isFree && freeMax ? getString("game.favorites.publicLimit.1") : getString("tools.favorite.instructions");
    const buttonText = isFree && freeMax ? getString("upgrade.title.1") : getString("games.all.1");
    const buttonRoute = isFree && freeMax ? routeCodes.MANAGE : routeCodes.GAMES;

    if (isStudent) {
      return null;
    }

    return (
      <div className="instructions">
        {instructionsText}
        &nbsp;&nbsp;
        <button
          type="button"
          onClick={() => {
            navigate(buttonRoute);
            if (isFree && freeMax) {
              mpSubCTASelect("favorite games tool", "in content", getString("upgrade.title.1"));
            }
          }}
          className="button-flat-color pt-cyan round auto"
        >
          {buttonText}
          <Arrow className="inline-arrow" />
        </button>
      </div>
    );
  };

  const isStudent = role === userRoles.student;
  const favStyle = isStudent ? "odd" : "even";

  return (
    <div id="favorite" className={`tools-favorite wrapper padded ${favStyle}`}>
      <div className="container">
        <h2>{getString("tools.favorite.title.1")}</h2>

        {renderInstructions(isStudent)}

        <div className="controls">
          <OptionallyDisplayed doDisplay={loadingGames || apiLoading}>
            <LoadingAnim>
              <h4>{getString("game.favorites.removing")}</h4>
            </LoadingAnim>
          </OptionallyDisplayed>

          <OptionallyDisplayed doDisplay={!(loadingGames || apiLoading)}>
            {error && <ErrorMessage error={error} />}

            {renderFavGames()}
          </OptionallyDisplayed>
        </div>
      </div>
    </div>
  );
}

FavoriteGames.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  setUpdating: PropTypes.func.isRequired,
};
