import { adData, variousURLs, tokenName, recaptchaScriptSrc, userRoles, getLangCode, getAbcyaUrl } from "../config";
import { getString, isLoggedInWithRole, isLoggedInWithoutRole } from "./";

let abcyaLoadAdScriptsInit = false;

export const loadReCaptchaScript = () => {
  if (!window.grecaptcha) {
    const appHead = document.getElementsByTagName("head")[0];
    const recaptchaScript = document.createElement("script");

    recaptchaScript.src = recaptchaScriptSrc;
    appHead.appendChild(recaptchaScript);
  }
};

export const getSchema = (schema) => {
  const keywords = Array.isArray(schema.keywords) ? schema.keywords.join(", ") : schema.keywords;

  const innerHTML = {
    "@context": variousURLs.schema,
    "@type": schema.type,
    url: `${getAbcyaUrl()}${schema.url}`,
    name: schema.name,
    description: schema.description,
    keywords,
    alternateName: getString("head.defaultTitle"),
    logo: variousURLs.logo[getLangCode()],
    sameAs: [variousURLs.facebook, variousURLs.twitter, variousURLs.instagram, variousURLs.vimeo],
  };

  if (schema.uploadDate && schema.uploadDate.length > 0) {
    innerHTML.uploadDate = schema.uploadDate;
  }

  if (schema.thumbnailUrl && schema.thumbnailUrl.length > 0) {
    innerHTML.thumbnailUrl = [schema.thumbnailUrl];
  }

  const ss = {
    type: "application/ld+json",
    innerHTML: JSON.stringify(innerHTML),
  };
  return [ss];
};

export const getMeta = (metas) => {
  return metas.map((item) => {
    const itemKeys = Object.keys(item);

    const meta = {};

    itemKeys.forEach((prop) => {
      if (prop === "content") {
        meta.content = Array.isArray(item.content) ? item.content.join(", ") : item.content;
      } else {
        meta[prop] = item[prop];
      }
    });

    return meta;
  });
};

const loadAdScripts = (adScriptsReady) => {
  if (!adScriptsReady && !abcyaLoadAdScriptsInit) {
    abcyaLoadAdScriptsInit = true;
    let scriptsCounter = 0;
    const appHead = document.getElementsByTagName("head")[0];

    const loadScript = () => {
      const adScript = adData.headScripts[scriptsCounter];
      const headScript = document.createElement("script");

      if (adScript.type) {
        headScript.type = adScript.type;
      }
      if (adScript.async) {
        headScript.async = adScript.async;
      }
      if (adScript.crossorigin) {
        headScript.crossorigin = adScript.crossorigin;
      }
      if (adScript.src) {
        headScript.src = adScript.src;
      }
      if (adScript.text) {
        headScript.text = adScript.text;
      }

      if (scriptsCounter++ < adData.headScripts.length - 1) {
        if (adScript.src) {
          headScript.onload = loadScript;
        } else {
          window.setTimeout(loadScript, 0);
        }
      }

      appHead.appendChild(headScript);
    };

    loadScript();
  }
};

export const handleAdScriptsLoading = () => {
  const adScriptsReady = Object.prototype.hasOwnProperty.call(window, "SD_AD");

  let cachedToken = "";
  try {
    cachedToken = window.localStorage.getItem(tokenName);
  } catch (e) {}
  if ((!cachedToken || isLoggedInWithRole(userRoles.free)) && !adScriptsReady) {
    // no token present, always loads ad scripts
    loadAdScripts(adScriptsReady);
  } else if (adScriptsReady && isLoggedInWithoutRole(userRoles.free)) {
    window.location.reload();
  }
};
