import { useClient, useMutation } from "urql";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { isMobileSafari, isSafari } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { breakpoints, recaptchaSiteKey } from "../config";
import { LOGIN_MUTATION } from "../graphql/mutations";
import { handlePostRequest, login } from "../utilities";

import { getScrollbarWidth, mobile } from "../utilities/detection";
import { loginModalSelector, modalToggle, pathSelector } from "../redux/slices/loginSlice";
import { decrementSeconds, isOnSelector, remainingSecondsSelector } from "../redux/slices/timerSlice";
import { gameProxySelector, mobileGameSelector } from "../redux/slices/fullPageSlice";
import { RECAPTCHA_VERIFY_QUERY } from "../graphql/queries";
import routeCodes from "../config/routeCodes";
/* Hook that triggers onClose when clicked outside of ref */

export const useOutsideClicker = (ref, outsideClickHandler) => {
  useEffect(() => {
    function clickOutsideHandler(e) {
      // console.log('ref.current: ', ref.current)
      // console.log('e.target: ', e.target)
      if (ref.current && ref.current.contains(e.target)) {
        // console.log('clicked on the element itself')
        return;
      }

      // console.log('clicked outside')
      outsideClickHandler();
    }

    // document.addEventListener('touchstart', clickOutsideHandler)
    document.addEventListener("click", clickOutsideHandler);
    return () => {
      // document.removeEventListener('touchstart', clickOutsideHandler)
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [ref]);
};

export const useWidthSize = (sizeArray) => {
  const desktopSafari = !mobile && isSafari;
  const safariScrollWidth = useMemo(getScrollbarWidth, []);
  const scrollbarWidth = desktopSafari ? safariScrollWidth : 0;

  const getWidthSize = () => {
    // update sizes & direction of browser window
    const { width } = breakpoints;

    // over ride adsLG for desktop safari
    // browser width doesn't account for scrollbars
    // causing missmatch with css breakpoints
    const xs = desktopSafari ? width.xs + scrollbarWidth : width.xs;
    const sm = desktopSafari ? width.sm + scrollbarWidth : width.sm;
    const md = desktopSafari ? width.md + scrollbarWidth : width.md;
    const lg = desktopSafari ? width.lg + scrollbarWidth : width.lg;
    const adsLG = desktopSafari ? width.adsLG + scrollbarWidth : width.adsLG;
    const xl = desktopSafari ? width.xl + scrollbarWidth : width.xl;
    const xxl = desktopSafari ? width.xxl + scrollbarWidth : width.xxl;

    const currentWidth = isMobileSafari ? document.documentElement.clientWidth : window.innerWidth;

    // assign the widthSize based on width of browser & breakpoints
    // breakpoint values are defined in 'js/config/index.js'
    let widthSize = "";

    if (currentWidth < xs) {
      widthSize = "xxs";
    } else if (currentWidth >= xs && currentWidth < sm) {
      widthSize = "xs";
    } else if (currentWidth >= sm && currentWidth < md) {
      widthSize = "sm";
    } else if (currentWidth >= md && currentWidth < lg) {
      widthSize = "md";
    } else if (currentWidth >= lg && currentWidth < adsLG) {
      widthSize = "lg";
    } else if (currentWidth >= adsLG && currentWidth < xl) {
      widthSize = "adsLG";
    } else if (currentWidth >= xl && currentWidth < xxl) {
      widthSize = "xl";
    } else {
      widthSize = "xxl";
    }

    return widthSize;
  };

  const [show, setShow] = useState(sizeArray && sizeArray.length > 0 ? sizeArray.includes(getWidthSize()) : true);

  const updateShow = () => {
    setShow(sizeArray && sizeArray.length > 0 ? sizeArray.includes(getWidthSize()) : true);
  };

  useEffect(() => {
    window.addEventListener("resize", updateShow);
    return () => window.removeEventListener("resize", updateShow);
  }, []);

  return show;
};

export const useIsGameProxy = () => {
  const location = useLocation();
  return location.pathname.startsWith(routeCodes.GAMEPROXY);
};

export const useQueryHandlers = (result, onCompleted, onError) => {
  useEffect(() => {
    if (!result.fetching && !result.error && result.data) {
      onCompleted(result.data);
    }
  }, [result.data]);

  useEffect(() => {
    if (!result.fetching && result.error && onError) {
      onError(result.error);
    }
  }, [result.error]);
};

// Meant for queries that are invoked on mount or on demand, and not necessarily on variable change.
export const useLazyQuery = (query, onCompleted, onError) => {
  const [loading, setLoading] = useState(false);
  const client = useClient();

  const executeQuery = (variables) => {
    setLoading(true);
    client
      .query(query, variables ? variables : undefined)
      .toPromise()
      .then((result) => {
        handlePostRequest(result, onCompleted, onError ? onError : undefined);
        setLoading(false);
      });
  };

  return { loading, executeQuery };
};

export const useLoginForm = () => {
  const action = `Login_Form_Submission`;
  const [submitting, setSubmitting] = useState();
  const [submitErrors, setSubmitErrors] = useState();

  const path = useSelector(pathSelector);
  const loginModal = useSelector(loginModalSelector);
  const dispatch = useDispatch();
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm();

  const loginUser = (data) => {
    const loginData = { token: data.login.token };

    if (path && path.length > 0) {
      loginData.path = path;
    }

    if (loginModal) {
      dispatch(modalToggle());
    }

    login(loginData);
  };

  const [result, loginMutation] = useMutation(LOGIN_MUTATION);
  useQueryHandlers(result, loginUser, () => setSubmitting(false));

  const onCompleted = (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      loginMutation({
        emailAddress: getValues().email,
        password: getValues().password,
      });
    } else {
      // not verified
      setSubmitting(false);
      setSubmitErrors({ message: reCaptchaVerify.message });
    }
  };

  const onError = (error) => {
    setSubmitting(false);
    setSubmitErrors({ message: error.toString() });
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onCompleted,
    onError,
  );

  const loginSubmit = () => {
    setSubmitting();

    // perform reCaptcha to get token
    window.grecaptcha.enterprise.ready(async () => {
      const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

      if (token) {
        recaptchaVerify({ token, action, verify: true, siteKey: recaptchaSiteKey });
      }
    });
  };

  return {
    error: result.error,
    errors,
    handleSubmit,
    loading: result.fetching,
    loginMutation,
    loginSubmit,
    recaptchaLoading,
    register,
    setValue,
    submitErrors,
    submitting,
  };
};

export const useTimer = () => {
  const dispatch = useDispatch();
  const isOn = useSelector(isOnSelector);
  const remainingSeconds = useSelector(remainingSecondsSelector);

  useEffect(() => {
    if (isOn) {
      setTimeout(() => dispatch(decrementSeconds()), 1000);
    }
  }, [remainingSeconds]);
};

export const useFullPage = () => {
  const rudy = document.getElementById("root");
  const mobileGame = useSelector(mobileGameSelector);
  const gameProxy = useSelector(gameProxySelector);

  useEffect(() => {
    if (mobileGame || gameProxy) {
      rudy?.classList.remove("fixed-padding");
    } else {
      rudy?.classList.add("fixed-padding");
    }
  }, [mobileGame, gameProxy]);
};
