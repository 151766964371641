import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { metaDefault } from "../../config/meta";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";
import Testimony from "./testimony";
import { routeCodes } from "../../config/routes";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { getLangCode, language } from "../../config";

export default function Testimonials() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const renderTestimonials = () => {
    const { data } = aboutStrings.testimonials;
    if (data && data.length > 0) {
      return data.map((obj, index) => {
        const testKey = `testKey${index}`;
        return <Testimony key={testKey} data={obj} />;
      });
    }
    return null;
  };

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: getString(getLangCode() === language.la ? "testimonials.metaTitle" : "testimonials.title.1", {
          strings: aboutStrings,
        }),
      })}

      <div className="testimonials">
        <div className="wrapper padded testimonials-header">
          <div className="container">
            <h1>{getString("testimonials.title.0", { strings: aboutStrings })}</h1>
          </div>

          <div className="container-medium pt-caption">
            {getString("testimonials.caption", { strings: aboutStrings })}
          </div>

          <OptionallyDisplayed doDisplay={!isLoggedIn}>
            <button
              data-testid="top-join"
              type="button"
              className="button-flat-color pt-cyan round"
              onClick={() => {
                navigate(routeCodes.SUBSCRIBE);
              }}
            >
              {getString("join.6", { html: true })}
            </button>
          </OptionallyDisplayed>
        </div>

        <div className="wrapper padded testimonials-wrapper">
          <div className="container testimonials-container">{renderTestimonials()}</div>
        </div>

        <OptionallyDisplayed doDisplay={!isLoggedIn}>
          <div className="wrapper padded">
            <div className="pt-footer-container">
              <h2>{getString("testimonials.join", { strings: aboutStrings })}</h2>

              <button
                data-testid="bottom-join"
                type="button"
                className="button-flat-color pt-cyan round"
                onClick={() => {
                  navigate(routeCodes.SUBSCRIBE);
                }}
              >
                {getString("join.6", { html: true })}
              </button>
            </div>
          </div>
        </OptionallyDisplayed>
      </div>

      <div className="wrapper header-shadow" />
    </>
  );
}
