import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { gtmJoinClick } from "../../utilities/gtm";
import { getString, isLoggedInWithoutRole } from "../../utilities";
import { userRoles } from "../../config";
import { routeCodes } from "../../config/routes";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { mpPageView, mpSubCTASelect } from "../../utilities/mixpanel";

export default function PremiumPlanet() {
  const isLoggedIn = useSelector(isLoggedInSelector);

  const premMessage = !isLoggedIn ? getString("join.5") : getString("upgrade.title.0");
  const premRoute = !isLoggedIn ? routeCodes.SUBSCRIBE : routeCodes.MANAGE;

  if (isLoggedInWithoutRole(userRoles.free)) {
    return null;
  }

  return (
    <Link
      className="home-planet premium"
      to={premRoute}
      onClick={() => {
        if (!isLoggedIn) {
          gtmJoinClick("/", "home-planet-button");
          mpSubCTASelect("general", "home-planet", premMessage);
        }
      }}
    >
      <span>{premMessage}</span>
    </Link>
  );
}
