import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "urql";

import { MANAGE_ACCOUNT_MUTATION } from "../../graphql/mutations";
import { getString, handlePostRequest } from "../../utilities";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import ErrorMessage from "../global/errorMessage";
import LoadingAnim from "../global/loadingAnim";
import PasswordConfirmModal from "../global/passwordConfirmModal";
import PaymentFields from "./paymentFields";
import NotAppliedModal from "../global/notAppliedModal";
import { submitError, subscribeSubmit } from "../../utilities/subscribe";
import { recaptchaSiteKey, tokenName } from "../../config";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function PaymentInfo({ success, isUpdating, setIsUpdating, isPremiumWeb, isCanceled }) {
  const [total, setTotal] = useState(0);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardNotApplied, setGiftCardNotApplied] = useState(false);
  const [notAppliedModal, setNotAppliedModal] = useState({ show: false });
  const [submitErrors, setSubmitErrors] = useState(null);
  const [creditComplete, setCreditComplete] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const { getValues, register, trigger, watch, formState } = useForm();

  const creditNotRequired = giftCards.length > 0 || creditComplete;
  const canUpdate = !isUpdating && creditNotRequired;

  const onUpdateCompleted = (data) => {
    const { token } = data.updateUser;
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      cardElement.clear();
    }

    try {
      window.localStorage.setItem(tokenName, token);
    } catch (e) {}
    success({ message: <p>{getString("manage.payment.success")}</p>, reload: true, token });
  };

  const [result, updateUser] = useMutation(MANAGE_ACCOUNT_MUTATION);
  const { fetching: loading } = result;

  const onRecaptchaCompleted = async (data) => {
    const myVariables = { giftCards };
    const cardElement = elements.getElement(CardElement);
    await subscribeSubmit({
      data,
      setSubmitErrors,
      cardElement,
      mutation: (variables) =>
        updateUser(variables).then((result) => handlePostRequest(result, onUpdateCompleted, onError)),
      creditNotRequired,
      setSubmitting: setIsUpdating,
      myVariables,
      creditComplete,
      action,
      stripe,
    });
  };

  const onError = (error) => {
    submitError(error, setSubmitErrors, setIsUpdating);
  };

  const action = "Payment_Form_Submission";
  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onError,
  );

  if (isPremiumWeb && !isCanceled) {
    return (
      <div id="payment" className="wrapper padded">
        <h2>{getString("manage.payment.title.1")}</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (canUpdate) {
              if (giftCardNotApplied) {
                // there is a giftcard field filled out but not applied
                // modal call to action to apply giftcard
                setNotAppliedModal({ show: true, type: "giftCard" });
              } else {
                setPasswordModal((prev) => !prev);
              }
            }
          }}
        >
          <div className="container-small">
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <FormProvider
              getValues={getValues}
              register={register}
              trigger={trigger}
              watch={watch}
              formState={formState}
            >
              <PaymentFields
                setGiftCardNotApplied={setGiftCardNotApplied}
                total={total}
                setTotal={setTotal}
                giftCards={giftCards}
                setGiftCards={setGiftCards}
                setCreditComplete={setCreditComplete}
                submitErrors={submitErrors}
                setSubmitErrors={setSubmitErrors}
              />
            </FormProvider>

            <div className="manage-form-footer">
              <button type="submit" className="button-flat-color pt-green manage-update-button" disabled={!canUpdate}>
                {getString("update.0")}
              </button>
            </div>
          </div>
        </form>

        <NotAppliedModal
          doShow={notAppliedModal.show}
          type={notAppliedModal.type || "giftCard"}
          close={() => {
            setNotAppliedModal({ show: false });
          }}
        />

        <PasswordConfirmModal
          doShow={!!passwordModal}
          close={() => {
            setPasswordModal((prev) => !prev);
            setIsUpdating(false);
          }}
          submit={() => {
            setIsUpdating(true);
            setPasswordModal((prev) => !prev);

            window.grecaptcha.enterprise.ready(async () => {
              const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

              if (token) {
                recaptchaVerify({ token, action, verify: true, siteKey: recaptchaSiteKey });
              }
            });
          }}
        >
          <p>{getString("manage.password")}</p>
        </PasswordConfirmModal>

        {(loading || recaptchaLoading || isUpdating) && (
          <LoadingAnim position="absolute" className="background-transparent-pt-grey">
            <h4>{getString("update.1")}</h4>
          </LoadingAnim>
        )}
      </div>
    );
  }

  return null;
}

PaymentInfo.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  isPremiumWeb: PropTypes.bool.isRequired,
  isCanceled: PropTypes.bool.isRequired,
};
