import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useMutation } from "urql";
import { getString, handlePostRequest } from "../../utilities";
import { getLangCode, language, userRoles } from "../../config";
import { ADD_HIDDEN_CATEGORY, REMOVE_HIDDEN_CATEGORY } from "../../graphql/mutations";
import Control from "./control";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import Instructions from "./instructions";
import Unlock from "../../../assets/svg/unlock.svg";
import Lock from "../../../assets/svg/lock.svg";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { gradeCategories } from "../../config/categories";
import { hiddenCategoriesSelector, roleSelector, setHiddenCategories } from "../../redux/slices/loginSlice";

export default function CategoryControl({ isUpdating, setUpdating }) {
  const [error, setError] = useState(null);
  const hiddenCategories = useSelector(hiddenCategoriesSelector);
  const role = useSelector(roleSelector);
  const dispatch = useDispatch();

  const hideSuccess = (data) => {
    const { hiddenCategories } = data.hideCategory;
    setUpdating(false);
    dispatch(setHiddenCategories({ hiddenCategories }));
    try {
      window.localStorage.setItem("hiddenCategories", JSON.stringify(hiddenCategories));
    } catch (e) {}
  };

  const revealSuccess = (data) => {
    const { hiddenCategories } = data.revealCategory;
    setUpdating(false);
    dispatch(setHiddenCategories({ hiddenCategories }));
    try {
      window.localStorage.setItem("hiddenCategories", JSON.stringify(hiddenCategories));
    } catch (e) {}
  };

  const failure = (error) => {
    setUpdating(false);
    setError(error);
  };

  const [hideResult, hideCategoryMutation] = useMutation(ADD_HIDDEN_CATEGORY);
  const { fetching: hideLoading } = hideResult;

  const [revealResult, revealCategoryMutation] = useMutation(REMOVE_HIDDEN_CATEGORY);
  const { fetching: revealLoading } = revealResult;

  const catClickHandler = (id) => {
    if (!isUpdating) {
      const hiddenCats = hiddenCategories.map((cat) => cat.id);
      const isHidden = hiddenCats.includes(id);

      const variables = { id };

      setUpdating(true);
      if (isHidden) {
        revealCategoryMutation(variables).then((result) => handlePostRequest(result, revealSuccess, failure));
      } else {
        hideCategoryMutation(variables).then((result) => handlePostRequest(result, hideSuccess, failure));
      }
    }
  };

  const renderCats = (disabled) => {
    const hiddenCats = hiddenCategories.map((cat) => cat.id);

    return gradeCategories.map((cat) => {
      return (
        <Control
          key={`key${cat.id}`}
          isActive={!hiddenCats.includes(cat.id)}
          isDisabled={disabled}
          label={cat.name}
          icon={{ active: <Unlock />, inactive: <Lock /> }}
          clickFunc={() => {
            catClickHandler(cat.id);
          }}
          {...(getLangCode() === language.la && { onText: getString("on.1") })}
          {...(getLangCode() === language.la && { offText: getString("off.1") })}
          className={getLangCode()}
        />
      );
    });
  };

  if (role === userRoles.student) {
    return null;
  }

  const isFree = role === userRoles.free;

  return (
    <div id="category" className="tools-category wrapper padded odd">
      <div className="container">
        <h2>{getString("tools.category.title.1")}</h2>

        <Instructions isFree={isFree} feature="category control tool">
          {getString("tools.category.instructions")}
        </Instructions>

        <div className="controls">
          <OptionallyDisplayed doDisplay={hideLoading || revealLoading}>
            <LoadingAnim>
              <h4>{getString("update.1")}</h4>
            </LoadingAnim>
          </OptionallyDisplayed>

          <OptionallyDisplayed doDisplay={!(hideLoading || revealLoading)}>
            {error && <ErrorMessage error={error} />}

            {renderCats(isFree || isUpdating)}
          </OptionallyDisplayed>
        </div>
      </div>
    </div>
  );
}

CategoryControl.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  setUpdating: PropTypes.func.isRequired,
};
