import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "urql";
import {
  DEVICE_COUNT_CHAR_LIMIT,
  EMAIL_CHAR_LIMIT,
  getLangCode,
  recaptchaSiteKey,
  STUDENT_COUNT_CHAR_LIMIT,
  TEXT_FIELD_CHAR_LIMIT,
} from "../../config";
import { routeCodes } from "../../config/routes";
import { SCHOOL_RFQ_MUTATION } from "../../graphql/mutations";

import { getString, handlePostRequest } from "../../utilities";
import { emailReg, getMaxLengthValidator } from "../../utilities/forms/rules";
import { gtmGAEvent } from "../../utilities/gtm";
import LoadingAnim from "../global/loadingAnim";
import OKModal from "../global/okModal";
import Select from "../global/Select";
import ErrorMessage from "../global/errorMessage";
import TextViewHook from "../global/textViewHook";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

const action = "RFQ_Form_Submission";

export default function SchoolRfqForm() {
  const location = useLocation();

  const [countriesArray, setCountriesArray] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [successModal, setSuccessModal] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    getValues,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const loadCountries = async () => {
      const { default: countryData } = await import(`../../utilities/countries/${getLangCode()}`);
      setCountriesArray(countryData);
    };

    loadCountries();
  }, []);

  const onSendCompleted = () => {
    setSubmitting(false);
    setSuccessModal(true);
    setSubmitErrors(null);
  };

  const onSendError = (error) => {
    setSubmitErrors({ message: error.message });
    setSubmitting(false);
  };

  const [result, sendRfqEmail] = useMutation(SCHOOL_RFQ_MUTATION);
  const { fetching: emailLoading } = result;

  const getVariables = () => {
    const { name, email, phone, position, country, schoolName, schoolAddress, deviceCount, studentCount } = getValues();
    return {
      name,
      email,
      phone,
      position,
      country: country.name,
      schoolName,
      address: schoolAddress,
      ...(deviceCount.length > 0 && { deviceCount: parseInt(deviceCount) }),
      studentCount: parseInt(studentCount),
    };
  };

  const onRecaptchaCompleted = ({ reCaptchaVerify }) => {
    if (reCaptchaVerify) {
      if (reCaptchaVerify.success) {
        // verified
        const variables = getVariables();
        variables.verifyKey = reCaptchaVerify.key;
        variables.verifyValue = action;

        if (variables) {
          sendRfqEmail(variables).then((result) => handlePostRequest(result, onSendCompleted, onSendError));
        }
      } else {
        // not verified
        setSubmitting(false);
        setSubmitErrors({ message: reCaptchaVerify.message });
      }
    }
  };

  const onRecaptchaError = (error) => {
    setSubmitting(false);
    setSubmitErrors({ message: error.message });
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  const loading = recaptchaLoading || emailLoading;

  return (
    <div className="school-rfq-form">
      <h2>{getString("subscribe.plans.school.requestQuote")}</h2>

      <form
        aria-label="form"
        onSubmit={handleSubmit(() => {
          // reset if errors are present
          if (submitErrors) {
            setSubmitErrors(null);
          }

          // perform reCaptcha to get token
          window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

            if (token) {
              recaptchaVerify({ token, action, verify: true, siteKey: recaptchaSiteKey });
            }
          });
        })}
      >
        <FormProvider register={register}>
          {submitErrors && <ErrorMessage error={submitErrors} />}

          <div className="cta">{getString("forms.yourContact")}</div>
          <TextViewHook
            name="name"
            errors={errors}
            placeholder={getString("forms.name.0")}
            label={getString("forms.name.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.name.0")] }),
              maxLength: getMaxLengthValidator(getString("forms.name.0"), TEXT_FIELD_CHAR_LIMIT),
            }}
          />
          <TextViewHook
            name="email"
            errors={errors}
            placeholder={getString("forms.email.0")}
            label={getString("forms.email.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.email.2")] }),
              maxLength: getMaxLengthValidator(getString("forms.email.2"), EMAIL_CHAR_LIMIT),
              pattern: {
                value: emailReg,
                message: getString("forms.errorMessages.email.0", {
                  replace: [getString("forms.email.0")],
                }),
              },
            }}
          />
          <TextViewHook
            name="phone"
            errors={errors}
            type="tel"
            placeholder={getString("forms.phone.0")}
            label={getString("forms.phone.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.phone.1")] }),
              maxLength: getMaxLengthValidator(getString("forms.phone.1"), TEXT_FIELD_CHAR_LIMIT),
            }}
          />
          <TextViewHook
            name="position"
            errors={errors}
            placeholder={getString("forms.position.0")}
            label={getString("forms.position.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.position.0")] }),
              maxLength: getMaxLengthValidator(getString("forms.position.0"), TEXT_FIELD_CHAR_LIMIT),
            }}
          />
          <div className="cta">{getString("forms.yourDistrictSchool")}</div>
          <Controller
            control={control}
            name="country"
            defaultValue=""
            rules={{
              required: getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.country.0")] }),
            }}
            render={({ field: { onChange }, fieldState }) => (
              <Select
                onChange={onChange}
                {...fieldState}
                placeholder={getString("forms.country.0")}
                items={countriesArray}
                searchKey="name"
              />
            )}
          />
          <TextViewHook
            name="schoolName"
            errors={errors}
            placeholder={getString("forms.schoolName.0")}
            label={getString("forms.schoolName.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", {
                replace: [getString("forms.schoolName.1")],
              }),
              maxLength: getMaxLengthValidator(getString("forms.schoolName.1"), TEXT_FIELD_CHAR_LIMIT),
            }}
          />
          <TextViewHook
            name="schoolAddress"
            errors={errors}
            placeholder={getString("forms.schoolAddress.0")}
            label={getString("forms.schoolAddress.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", {
                replace: [getString("forms.schoolAddress.1")],
              }),
              maxLength: getMaxLengthValidator(getString("forms.schoolAddress.1"), TEXT_FIELD_CHAR_LIMIT),
            }}
          />
          <TextViewHook
            name="deviceCount"
            errors={errors}
            placeholder={getString("forms.deviceCount.0")}
            label={getString("forms.deviceCount.0")}
            rules={{
              maxLength: getMaxLengthValidator(getString("forms.deviceCount.1"), DEVICE_COUNT_CHAR_LIMIT),
            }}
            type="number"
          />
          <TextViewHook
            name="studentCount"
            errors={errors}
            placeholder={getString("forms.studentCount.0")}
            label={getString("forms.studentCount.0")}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", {
                replace: [getString("forms.studentCount.1")],
              }),
              maxLength: getMaxLengthValidator(getString("forms.studentCount.1"), STUDENT_COUNT_CHAR_LIMIT),
            }}
            type="number"
          />
          <button
            className="button-flat-color pt-green"
            type="submit"
            onClick={() => {
              gtmGAEvent({
                eventAction: "button-click",
                eventCategory: "IXL/ABCya School Request for Quote",
                eventLabel: location.pathname,
              });
            }}
          >
            {getString("forms.getAQuote")}
          </button>
        </FormProvider>
      </form>

      <div data-testid="recaptcha-id" className="subscribe-terms">
        <p>{getString("recaptcha", { html: true })}</p>
      </div>

      <OKModal
        doShow={successModal}
        close={() => {
          navigate(routeCodes.HOME);
        }}
        title={getString("thankyou.1")}
        button={getString("subscribe.school.home")}
      >
        <p>{getString("subscribe.school.thankyou")}</p>
        <p>{getString("subscribe.school.respond")}</p>
      </OKModal>

      {(loading || submitting) && (
        <LoadingAnim position="absolute" className="background-transparent-white">
          <h4>{getString("send.1")}</h4>
        </LoadingAnim>
      )}
    </div>
  );
}
