import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { ME_QUERY } from "../../graphql/queries";
import { getAbcyaUrl, language, tokenName, userRoles, variousURLs } from "../../config";
import { handleAdScriptsLoading, loadReCaptchaScript } from "../../utilities/head";
import { gtmLogInOut } from "../../utilities/gtm";
import { useIsGameProxy, useLazyQuery } from "../../hooks";
import Main from "./main";
import { login, setShowRevalidateModal } from "../../redux/slices/loginSlice";
import { setAdBlocking } from "../../redux/slices/userClientSlice";
import { fetchStartupData } from "../../redux/slices/dataSlice";
import { getScrollbarWidth, isCrawler, isPrerender } from "../../utilities/detection";
import { useLocation, useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { mpLoginSuccess } from "../../utilities/mixpanel";

let token;

try {
  token = window.localStorage.getItem(tokenName);
} catch (error) {
  console.log("token error: ", error.message);
}

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isGameProxy = useIsGameProxy();

  const invalid = () => {
    // most likely token is present but not valid
    dispatch(setShowRevalidateModal());
  };

  const checkAdBlocking = () => {
    let counter = 0;
    const intervalId = setInterval(() => {
      if (counter >= 5) {
        clearInterval(intervalId);
        return;
      }
      ++counter;
      if (window.adsbygoogle) {
        if (window.adsbygoogle.push.length === 0) {
          dispatch(setAdBlocking({ adBlocking: true }));
        }
        clearInterval(intervalId);
      }
    }, 100);
    const appHead = document.getElementsByTagName("head")[0];

    variousURLs.adDetect.forEach((url) => {
      const googleAdScript = document.createElement("script");

      googleAdScript.onerror = () => {
        appHead.removeChild(googleAdScript);
        dispatch(setAdBlocking({ adBlocking: true }));
      };

      googleAdScript.onload = () => {
        appHead.removeChild(googleAdScript);
      };

      googleAdScript.type = "text/javascript";
      googleAdScript.src = url;
      googleAdScript.defer = true;
      appHead.appendChild(googleAdScript);
    });
  };

  const onCompleted = (data) => {
    if (data && data.me) {
      const { subscription, games, hiddenCategories, email, id, userType, logins, createdAt } = data.me;

      const tokenRole = jwtDecode(token).role;

      if (
        subscription.planGroup !== tokenRole &&
        !([userRoles.classroom, userRoles.school].includes(subscription.planGroup) && tokenRole === userRoles.student)
      ) {
        // If subscription role comes back different than the token role
        // and the token role ISN'T a student with a still valid school/classroom subscription role
        // log them out and make them log back in as their role has changed and need an updated token
        // ie was premium subscriber and has canceled/lapsed to a public/free user
        try {
          window.localStorage.removeItem(tokenName);
        } catch (e) {}
        invalid();
      } else {
        dispatch(
          login({
            role: tokenRole,
            planId: subscription.planId,
            billingPlatform: subscription.billingPlatform,
            games,
            hiddenCategories,
            email,
            id,
          }),
        );
        try {
          window.localStorage.setItem("planId", subscription.planId);
          window.localStorage.setItem("billingPlatform", subscription.billingPlatform);
          window.localStorage.setItem("games", JSON.stringify(games));
          window.localStorage.setItem("hiddenCategories", JSON.stringify(hiddenCategories));
        } catch (e) {}

        gtmLogInOut({ role: tokenRole, billingPlatform: subscription.billingPlatform });

        try {
          // If first time login, "isLoginBeforeByForm" does not exist in local storage. Form could be the login modal or login form on the free signup page.
          // If not first time login, "isLoginBeforeByForm" exists and set to any value (here we set it to "1")
          const isLoginBeforeByForm = window.localStorage.getItem("isLoginBeforeByForm");
          if (isLoginBeforeByForm !== null) {
            mpLoginSuccess(tokenRole, logins, false, userType, subscription.planGroup, id, createdAt);
          } else {
            mpLoginSuccess(tokenRole, logins, true, userType, subscription.planGroup, id, createdAt);
            window.localStorage.setItem("isLoginBeforeByForm", "1");
          }
        } catch (e) {}
      }
    } else {
      invalid();
    }
  };

  const { executeQuery: meQuery } = useLazyQuery(ME_QUERY, onCompleted);

  useEffect(() => {
    // did mount
    const currentHostname = new URL(getAbcyaUrl()).hostname;
    const referrer = document.referrer ? new URL(document.referrer).hostname : null;

    const isHomePath = location.pathname === routeCodes.HOME;
    const notHomeRefer = referrer !== currentHostname;

    if (!isGameProxy && !isCrawler && !isPrerender) {
      // look for language preference token
      let langPref;

      try {
        langPref = window.localStorage.getItem(language.preferenceToken);
      } catch (error) {}

      if (location.hash && location.hash === language.resetHash) {
        if (langPref) {
          // if existing language preference, remove it
          try {
            window.localStorage.removeItem(language.preferenceToken);
          } catch (e) {}
        }
        // replace history location with url minus hash
        navigate(location.pathname, { replace: true });
      } else {
        // if home page redirect user to prefered localized site
        if (langPref && isHomePath && notHomeRefer) {
          window.location.replace(getAbcyaUrl(langPref));
        }
      }
    }

    document.documentElement.style.setProperty("--scrollbar-width", `${getScrollbarWidth()}px`);

    dispatch(fetchStartupData());
    if (token) {
      meQuery();
    }
    if (!isGameProxy) {
      loadReCaptchaScript();
      checkAdBlocking();
    }
  }, []);

  useEffect(() => {
    // did update
    if (!isGameProxy) {
      handleAdScriptsLoading();
    }
  });

  return <Main />;
}
