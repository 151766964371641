import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "urql";

import { SUBSCRIPTIONS_PLAN_QUERY } from "../../graphql/queries";
import { getString, getPlanData } from "../../utilities";
import { userRoles, billingPlatform, stripePublishableKeys } from "../../config";
import PlanButton from "./planButton";
import SubscribedModal from "./subscribedModal";
import Features from "./features";
import LoadingAnim from "../global/loadingAnim";
import { gtmSignUp, gtmSubscribed } from "../../utilities/gtm";
import ErrorMessage from "../global/errorMessage";
import routeCodes from "../../config/routeCodes";
import { getStripeLocale } from "../../utilities/stripe";
import { mpSignUpSuccess } from "../../utilities/mixpanel";

export default function Subscribe() {
  const isFreeRef = useRef(false);
  const stripePromise = useMemo(() => loadStripe(stripePublishableKeys[process.env.NODE_ENV]), []);

  const location = useLocation();

  const [submitting, setSubmitting] = useState(false);
  const [plan, setPlan] = useState("family");
  const [promo, setPromo] = useState(null);
  const [token, setToken] = useState("");
  const [freeData, setFreeData] = useState(null);
  const [familyData, setFamilyData] = useState(null);
  const [classroomData, setClassroomData] = useState(null);

  const methods = useForm({ mode: "onChange" });
  const { getValues, reset } = methods;
  const { accessName, accessCode } = getValues();

  useEffect(() => {
    reset();

    const routePlan = location.pathname.split("/").slice(-1).pop();
    if (
      [routeCodes.SUBSCRIBE_FREE, routeCodes.SUBSCRIBE_FAMILY, routeCodes.SUBSCRIBE_CLASSROOM].includes(
        location.pathname,
      )
    ) {
      setPlan(routePlan);
    }

    if (promo && !promo.planGroups.includes(routePlan.toUpperCase())) {
      setPromo(null);
    }
  }, [location]);

  const [result] = useQuery({
    query: SUBSCRIPTIONS_PLAN_QUERY,
    variables: { billingPlatform, planGroup: userRoles.free },
  });
  const { fetching: dataFetching, error: dataError, data } = result;

  useEffect(() => {
    if (data) {
      let premiumDataArray = [...data.subscriptions];
      let freeDataArray = null;

      for (let i = 0; i < premiumDataArray.length; i++) {
        if (premiumDataArray[i].planGroup === userRoles.free) {
          freeDataArray = premiumDataArray.splice(i, 1);
        }
      }

      premiumDataArray = { subscriptions: premiumDataArray };

      setFreeData(freeDataArray[0]);

      setFamilyData(getPlanData(premiumDataArray, userRoles.family, promo));
      setClassroomData(getPlanData(premiumDataArray, userRoles.classroom, promo));
    }
  }, [plan, data, promo]);

  const renderErrors = (error) => {
    if (error) {
      return (
        <div className="wrapper padded">
          <div className="container-medium">
            <ErrorMessage error={error} />
          </div>
        </div>
      );
    }

    return null;
  };

  const subscribeSuccess = (data, plan, options = {}, giftcardOrPromoUse = {}) => {
    const {
      signup: { id, subscription, token },
    } = data;

    isFreeRef.current = plan.planId.toLowerCase() === "free";

    // Event tracking
    if (isFreeRef.current) {
      gtmSignUp(id, { module: "free user signup form" });
    } else {
      gtmSubscribed(plan, options);
    }
    mpSignUpSuccess();

    try {
      window.localStorage.setItem("planId", plan.planId);
      window.localStorage.setItem("billingPlatform", subscription.billingPlatform);
    } catch (e) {}

    setSubmitting(false);
    setToken(token);
    return token;
  };

  const renderForm = () => {
    return (
      <Elements stripe={stripePromise} options={{ locale: getStripeLocale() }}>
        <FormProvider {...methods}>
          <Outlet
            context={{
              success: subscribeSuccess,
              promo,
              setPromo,
              freeData,
              familyData,
              classroomData,
              submitting,
              setSubmitting,
              // errors: methods.formState.errors,
              dataFetching,
              dataError,
            }}
          />
        </FormProvider>
      </Elements>
    );
  };

  return (
    <>
      <div className="subscribe">
        <div className="wrapper padded">
          <h1>{getString("join.3")}</h1>

          <h2>{getString("subscribe.cta")}</h2>
          <div className="planselect">
            <PlanButton to={routeCodes.SUBSCRIBE_FREE} color="pt-yellow" subType="free" />

            <PlanButton to={routeCodes.SUBSCRIBE_FAMILY} color="pt-cyan" promo={promo} subType="family" />

            <PlanButton to={routeCodes.SUBSCRIBE_CLASSROOM} color="pt-blue" promo={promo} subType="classroom" />
          </div>
        </div>

        {renderErrors(dataError)}
        {renderForm()}

        <Features familyData={familyData} classroomData={classroomData} promo={promo} />

        {dataFetching && <LoadingAnim position="fixed" className="background-transparent-white" />}
      </div>
      <div className="wrapper header-shadow" />
      <SubscribedModal token={token} accessName={accessName} accessCode={accessCode} isFree={isFreeRef.current} />
    </>
  );
}
