import React from "react";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { routeCodes } from "../../config/routes";
import { useLoginForm } from "../../hooks";
import { getString, logout } from "../../utilities";
import { gtmJoinClick } from "../../utilities/gtm";
import LoadingAnim from "../global/loadingAnim";
import Modal from "../global/modal";
import ModalClose from "../global/modalClose";
import LoginFields from "./LoginFields";
import { showRevalidateModalSelector } from "../../redux/slices/loginSlice";
import { mpSubCTASelect } from "../../utilities/mixpanel";

export default function RevalidateModal() {
  const { error, errors, handleSubmit, loading, loginSubmit, recaptchaLoading, register, submitErrors, submitting } =
    useLoginForm();
  const showRevalidateModal = useSelector(showRevalidateModalSelector);

  return (
    <Modal doShow={showRevalidateModal} closeOnEscape={() => logout()}>
      <ModalClose close={() => logout()} />
      <FormProvider handleSubmit={handleSubmit} register={register}>
        <div className="login">
          <div className="modal-content-header">{getString("login.invalid.title")}</div>
          <p>{getString("login.invalid.description")}</p>
          <LoginFields error={error} errors={errors} loginSubmit={loginSubmit} submitErrors={submitErrors} />
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              logout(routeCodes.FORGOT);
            }}
          >
            {getString("login.forgot")}
          </a>
          <div className="form-terms">{getString("recaptcha", { html: true })}</div>
          <div className="modal-content-footer">
            <p>
              <strong>{getString("login.notSubscriber")}</strong>
            </p>
            <button
              type="button"
              className="button-flat-color pt-orange"
              onClick={() => {
                gtmJoinClick(location.pathname, "login-modal-link");
                mpSubCTASelect("general", "none", getString("join.2"));
                logout(routeCodes.SUBSCRIBE);
              }}
            >
              {getString("join.2")}
            </button>
          </div>
        </div>
        {(loading || recaptchaLoading || submitting) && (
          <LoadingAnim className="background-white" position="absolute">
            <h4>{getString("login.loading")}</h4>
          </LoadingAnim>
        )}
      </FormProvider>
    </Modal>
  );
}
