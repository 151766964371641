import React, { useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormContext } from "react-hook-form";
import { useMutation } from "urql";

import { getLangCode, language, recaptchaSiteKey, userRoles } from "../../config";
import { metaDefault } from "../../config/meta";
import { SIGNUP_MUTATION } from "../../graphql/mutations";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest } from "../../utilities";
import AccountFields from "./accountFields";
import PaymentFields from "./paymentFields";
import FamilyPlans from "./familyPlans";
import { submitError, subscribeSubmit } from "../../utilities/subscribe";
import NotAppliedModal from "../global/notAppliedModal";
import SubscribeConfirm from "../global/subscribeConfirm";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";
import { mpPaymentSubmitted } from "../../utilities/mixpanel";

export default function FamilyForm() {
  const [plan, setPlan] = useState("m6");
  const [total, setTotal] = useState(0);
  const [hasPromoNotApplied, setHasPromoNotApplied] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardNotApplied, setGiftCardNotApplied] = useState(false);
  const [notAppliedModal, setNotAppliedModal] = useState({ show: false });
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [creditComplete, setCreditComplete] = useState(false);

  const { success, familyData, submitting, setSubmitting, promo, setPromo, dataFetching, dataError } =
    useOutletContext();

  const location = useLocation();

  const {
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const elements = useElements();
  const stripe = useStripe();

  const getVariables = () => {
    const { email, emailVerify, password, passwordVerify } = getValues();
    const myVariables = {
      email: email.trim(),
      emailVerify: emailVerify.trim(),
      password,
      passwordVerify,
      billingPlan: familyData[plan].planId,
      giftCards,
    };

    if (promo && promo.shortname) {
      myVariables.promoCode = promo.shortname;
    }

    return myVariables;
  };

  /* Need to check subscription fee/cost vs. giftcard applied total */
  const planData = familyData?.[plan];

  const onSignupCompleted = (data) => {
    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      cardElement.clear();
    }

    success(
      data,
      { planId: planData?.planId, amount: planData?.amount, name: planData?.name },
      { invoiceId: data?.signup?.invoiceId },
      { isGiftCardUsed: giftCards.length > 0, isPromoCodeUsed: promo !== null },
    );
    setSubmitErrors(null);
  };

  const onSignupError = (error) => {
    setSubmitting(false);
    setSubmitErrors(error);
  };

  const [result, signup] = useMutation(SIGNUP_MUTATION);
  const { fetching: loading } = result;

  let finalAmount = planData?.amount;

  if (promo) {
    if (promo.amountOff) {
      finalAmount = planData?.amount - promo.amountOff;
      if (finalAmount < 0) finalAmount = 0;
    }

    if (promo.percentOff) {
      finalAmount = planData?.amount - planData?.amount * promo.percentOff;
    }
  }

  const creditNotRequired = finalAmount < total;
  const action = "Family_Form_Submission";

  const onRecaptchaCompleted = async (data) => {
    const myVariables = getVariables();
    const cardElement = elements.getElement(CardElement);
    await subscribeSubmit({
      data,
      setSubmitErrors,
      cardElement,
      mutation: (variables) =>
        signup(variables).then((result) => handlePostRequest(result, onSignupCompleted, onSignupError)),
      creditNotRequired,
      creditComplete,
      action,
      stripe,
      myVariables,
      plan: { planId: planData?.planId, amount: planData?.amount, name: planData?.name },
      setSubmitting,
    });
  };

  const onRecaptchaError = (error) => {
    console.log("recaptcha error: ", error);
    submitError(error, setSubmitErrors, null);
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  const disabled = submitting || !familyData || dataFetching || dataError || loading || recaptchaLoading;

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: `${getString("join.3")} • ${getString("subscribe.plans.family.title.2")}`,
      })}

      <FamilyPlans plan={plan} setPlan={setPlan} promo={promo} setPromo={setPromo} planData={familyData} />

      <form
        disabled={disabled}
        onSubmit={handleSubmit(() => {
          if (!disabled) {
            if (giftCardNotApplied) {
              // there is a giftcard field filled out but not applied
              // modal call to action to apply giftcard
              setNotAppliedModal({ show: true, type: "giftCard" });
            } else if (hasPromoNotApplied) {
              // the promo code field is filled out but not applied
              // modal call to action to apply promo
              setNotAppliedModal({ show: true, type: "promo" });
            } else {
              setConfirmModal((prev) => !prev);
            }
            // Mixpanel Event
            mpPaymentSubmitted({
              months: plan,
              planGroup: userRoles.family,
              amount: planData?.amount,
              finalAmount,
              isGiftCardUsed: giftCards.length > 0,
              isPromoCodeUsed: promo !== null,
            });
          }
        })}
      >
        <div className="wrapper padded">
          <div className="container-small">
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <h2>{getString("subscribe.create")}</h2>

            <AccountFields
              errors={errors}
              submitErrors={submitErrors}
              setSubmitErrors={setSubmitErrors}
              isDisabled={disabled}
            />
            <PaymentFields
              total={total}
              setTotal={setTotal}
              setGiftCardNotApplied={setGiftCardNotApplied}
              giftCards={giftCards}
              setGiftCards={setGiftCards}
              errors={errors}
              hasPromoNotApplied={hasPromoNotApplied}
              setHasPromoNotApplied={setHasPromoNotApplied}
              doesNotRequireCredit={creditNotRequired}
              promo={promo}
              setPromo={setPromo}
              planGroup={userRoles.family}
              setCreditComplete={setCreditComplete}
              submitErrors={submitErrors}
              setSubmitErrors={setSubmitErrors}
              isNewSubscriber
              isDisabled={disabled}
            />

            <button type="submit" className="button-flat-color pt-green" disabled={disabled}>
              {getString("subscribe.submit")}
            </button>
            <div className="subscribe-terms">
              <p>
                {getString("subscribe.terms", {
                  replace: [
                    getLangCode() === language.la ? getString("subscribe.title.1") : getString("subscribe.title.0"),
                  ],
                  html: true,
                })}
              </p>
              <p>{getString("recaptcha", { html: true })}</p>
            </div>
          </div>
        </div>
      </form>

      <NotAppliedModal
        doShow={notAppliedModal.show}
        type={notAppliedModal.type || "giftCard"}
        close={() => {
          setNotAppliedModal({ show: false });
        }}
      />

      <SubscribeConfirm
        doShow={confirmModal}
        submit={() => {
          setConfirmModal((prev) => !prev);
          setSubmitting(true);

          window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

            if (token) {
              recaptchaVerify({ token, action, verify: true, siteKey: recaptchaSiteKey });
            }
          });
        }}
        cancel={() => {
          setConfirmModal((prev) => !prev);
        }}
        planData={{
          title: getString("subscribe.plans.family.title.2"),
          plan,
          amount: planData?.amount,
          finalAmount,
          promo,
          creditNotRequired,
          giftCards: { cards: giftCards, total },
        }}
      />

      {(loading || recaptchaLoading || submitting) && (
        <LoadingAnim position="fixed" className="background-transparent-white">
          <h4>{getString("subscribe.loading")}</h4>
        </LoadingAnim>
      )}
    </>
  );
}
