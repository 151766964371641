import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../global/modal";
import LoginForm from "./loginForm";
import Confirm from "../global/confirm";
import { logout, getString } from "../../utilities";
import { isLoggedInSelector, loginModalSelector, modalToggle } from "../../redux/slices/loginSlice";
import { mpModalShown } from "../../utilities/mixpanel";

export default function LoginModal() {
  const loginModal = useSelector(loginModalSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  const modalShownEventData = {
    modalType: "confirm-logout",
    triggerType: "button click",
    header: getString("navigation.logout.2"),
    primaryCta: getString("ok"),
    secondaryCta: "none",
    tertiaryCta: "none",
    isDismissible: true,
  };

  if (isLoggedIn) {
    return (
      <Modal doShow={loginModal} closeOnEscape={() => dispatch(modalToggle())}>
        <Confirm
          confirm={() => {
            mpModalShown({
              ...modalShownEventData,
              interaction: "primary cta",
            });
            logout();
          }}
          cancel={() => {
            mpModalShown({
              ...modalShownEventData,
              interaction: "dismissed",
            });
            dispatch(modalToggle());
          }}
        >
          <div className="modal-content-header">{getString("navigation.logout.2")}</div>
          <p>{getString("login.outConfirm")}</p>
        </Confirm>
      </Modal>
    );
  }
  return (
    <Modal doShow={loginModal} closeOnEscape={() => dispatch(modalToggle())}>
      <LoginForm />
    </Modal>
  );
}
