import React, { useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { adData } from "../../config";
import { messageOriginSelector, setClickToPlayVideo, setMessageOrigin } from "../../redux/slices/preContentVideoSlice";
import { sendResponse } from "../../utilities";

export default function ClickToPlayVideo() {
  const containerRef = useRef();
  const timeoutRef = useRef();
  const messageOrigin = useSelector(messageOriginSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const adId = adData.divIds.video.ctp;

  const resetClickToPlay = () => {
    dispatch(setClickToPlayVideo({ clickToPlayVideo: false }));
    dispatch(setMessageOrigin({ messageOrigin: null }));
  };

  const resumeGame = () => {
    const origin = messageOrigin;
    const message = { type: "resumeGame" };
    resetClickToPlay();
    console.log(
      `sending message to game to resume game play - message: ${JSON.stringify(message)}, origin: ${JSON.stringify(
        origin,
      )}`,
    );
    sendResponse(message, origin);
  };

  const init = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
  };
  const exit = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    resumeGame();
  };

  const containerCallback = useCallback(
    (elem) => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("videoadinit", init);
        containerRef.current.removeEventListener("videoadexit", exit);
      }
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
      containerRef.current = elem;
      if (containerRef.current) {
        containerRef.current.addEventListener("videoadinit", init);
        containerRef.current.addEventListener("videoadexit", exit);
        timeoutRef.current = window.setTimeout(exit, 5000); // exit if init does not fire within 5 seconds

        // now that container exists, call ads lib manual refresh (ctp did not exist on page load)
        const manualRefreshWhenAdsLibReady = () => {
          if (window.SD_AD && !window.SD_AD.getIsOpen()) {
            window.SD_AD.refresh([adId], "ctp");
          } else if (containerRef.current) {
            setTimeout(manualRefreshWhenAdsLibReady, 100);
          }
        };
        manualRefreshWhenAdsLibReady();
      }
    },
    [location],
  );

  return (
    <div className="a-prerollvideo-container">
      <video id={adId} width="640" height="480" autoPlay>
        <source src="/static/assets/blankVideo5s.mp4" type="video/mp4" />
      </video>
      <div id={`${adId}-container`} ref={containerCallback} />
    </div>
  );
}
