import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { adData } from "../../config";
import { setPreContentVideo } from "../../redux/slices/preContentVideoSlice";

export default function PreContentVideo() {
  const containerRef = useRef();
  const timeoutRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const adId = adData.divIds.video.ap;

  const init = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
  };
  const exit = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    dispatch(setPreContentVideo({ preContentVideo: false }));
  };
  const containerCallback = useCallback(
    (elem) => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("videoadinit", init);
        containerRef.current.removeEventListener("videoadexit", exit);
      }
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
      containerRef.current = elem;
      if (containerRef.current) {
        containerRef.current.addEventListener("videoadinit", init);
        containerRef.current.addEventListener("videoadexit", exit);
        timeoutRef.current = window.setTimeout(exit, 5000); // exit if init does not fire within 5 seconds
      }
    },
    [location],
  );

  return (
    <div className="a-prerollvideo-container">
      <video id={adId} width="640" height="480" autoPlay muted>
        <source src="/static/assets/blankVideo5s.mp4" type="video/mp4" />
      </video>
      <div id={`${adId}-container`} ref={containerCallback} />
    </div>
  );
}
