import React from "react";
import PropTypes from "prop-types";
import Modal from "./modal";
import Confirm from "./confirm";
import { getString } from "../../utilities";
import { mpModalShown } from "../../utilities/mixpanel";

export default function LeavingSiteModal({ doShow, cancelFunction, url }) {
  const modalShownEventData = {
    modalType: "confirm-leaving-site",
    triggerType: "button click",
    header: getString("attention.0"),
    primaryCta: getString("continue.0"),
    secondaryCta: "none",
    tertiaryCta: "none",
    isDismissible: true,
  };

  return (
    <Modal doShow={doShow} closeOnEscape={cancelFunction}>
      <Confirm
        confirm={() => {
          // Mixpanel event
          mpModalShown({
            ...modalShownEventData,
            interaction: "primary cta",
          });

          // close the door(modal) behind you
          cancelFunction();

          window.open(url, "_blank", "noopener,noreferrer");
        }}
        cancel={() => {
          // Mixpanel event
          mpModalShown({
            ...modalShownEventData,
            interaction: "dismissed",
          });

          cancelFunction();
        }}
        button={getString("continue.0")}
        cancelButton={getString("cancel.4")}
      >
        <div className="modal-content-header">{getString("attention.0")}</div>
        <div className="leaving-modal">
          <p>{getString("leaving.message")}</p>
          <p>{getString("leaving.cta")}</p>

          <p className="leaving-modal-disclaimer">{getString("leaving.disclaimer")}</p>
        </div>
      </Confirm>
    </Modal>
  );
}

LeavingSiteModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  url: PropTypes.string,
};
