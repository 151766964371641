import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import defaultSSHorizontalJpg from "../../../assets/img/defaultScreenshotHorizontal.jpg";
import defaultSSVerticalJpg from "../../../assets/img/defaultScreenshotVertical.jpg";
import defaultSSHorizontalWebp from "../../../assets/img/defaultScreenshotHorizontal.webp";
import defaultSSVerticalWebp from "../../../assets/img/defaultScreenshotVertical.webp";
import { mobileFreePremium } from "../../utilities/games";
import MobilePlayButton from "./mobilePlayButton";
import { getString, isLoggedInWithRole } from "../../utilities";
import { userRoles, assetUrl } from "../../config";
import Modal from "../global/modal";
import ModalClose from "../global/modalClose";
import Arrow from "../../../assets/svg/arrowRight.svg";
import WebP from "../global/WebP";
import { modalToggle } from "../../redux/slices/loginSlice";

export default function MobileContent({ game, isMobileActive, isArcademics, isMobileFree, publicModalToggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [searchParams] = useSearchParams();
  const shortnameOverride = searchParams.get("shortname");

  const ssClickHandler = () => {
    if (game.mobileActive) {
      // game is mobile ready
      // pass media url to game in state
      if (mobileFreePremium(isMobileActive, isMobileFree)) {
        navigate(
          {
            pathname: `${routeCodes.MOBILEGAMES}${game.localeShortname || game.shortname}`,
            ...(shortnameOverride && { search: createSearchParams({ shortname: shortnameOverride }).toString() }),
          },
          {
            state: { game, arcademics: isArcademics },
          },
        );
      } else if (isLoggedInWithRole(userRoles.free)) {
        publicModalToggle();
      } else {
        dispatch(modalToggle());
      }
    } else {
      setModal(true);
    }
  };

  const renderScreenShots = (game) => {
    if (game.screenshots && game.screenshots.length > 0) {
      return game.screenshots.map((ss) => (
        <WebP secret={`${assetUrl}${ss}`} imgProps={{ alt: game.fullname, fetchpriority: "high" }} key={ss} />
      ));
    }

    const defaultVertical =
      (game.mobileOrientation && game.mobileOrientation === "portrait") || game.orientation === "portrait";

    return (
      <picture>
        <source srcSet={defaultVertical ? defaultSSVerticalWebp : defaultSSHorizontalWebp} type="image/webp" />
        <source srcSet={defaultVertical ? defaultSSVerticalJpg : defaultSSHorizontalJpg} type="image/jpeg" />

        <img src="" alt={game.fullname} />
      </picture>
    );
  };

  const ti = 1;

  return (
    <>
      <div onClick={ssClickHandler} tabIndex={ti} className="game-mobile-screenshots">
        {renderScreenShots(game)}
        <MobilePlayButton />
      </div>

      <Modal doShow={modal} closeOnEscape={() => setModal((prev) => !prev)}>
        <ModalClose close={() => setModal((prev) => !prev)} />
        <div className="modal-content-header">{getString("game.mobile.notAvailable", { html: true })}</div>

        <div style={{ marginBottom: "1rem" }}>
          <button
            type="button"
            onClick={() => {
              navigate(routeCodes.GAMES);
            }}
            className="button-flat-color pt-green round auto"
          >
            {getString("game.mobile.find")}
            <Arrow className="inline-arrow" />
          </button>
        </div>
        <h4>{getString("game.mobile.play")}</h4>
      </Modal>
    </>
  );
}

MobileContent.propTypes = {
  game: PropTypes.object.isRequired,
  isMobileActive: PropTypes.bool.isRequired,
  isArcademics: PropTypes.bool.isRequired,
  isMobileFree: PropTypes.bool.isRequired,
  publicModalToggle: PropTypes.func.isRequired,
};
