import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Routes, { routeCodes } from "../../config/routes";
import { metaMain } from "../../config/meta";
import Header from "../header";
import Footer from "../footer";
import LoginModal from "../login/loginModal";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { freePublicUser, getString, removeTrailingSlash } from "../../utilities";
import OKModal from "../global/okModal";
import CookieBanner from "../global/cookieBanner";
import AdblockModalMessage from "../global/adblockModalMessage";
import AdblockIframe from "../global/adblockIframe";
import { gtmPageView } from "../../utilities/gtm";
import MessageHandler from "../global/messageHandler";
import UpgradePremiumModal from "../global/upgradePremiumModal";
import ServiceDownWarningModal from "../global/serviceDownWarningModal";
import RevalidateModal from "../login/RevalidateModal";
import { alertToggle, showAlertModalSelector } from "../../redux/slices/timerSlice";
import { useFullPage, useTimer } from "../../hooks";
import LeavingSiteModal from "../global/leavingSiteModal";
import { setShowLeaveModal, showLeaveModalSelector, urlSelector } from "../../redux/slices/leavingSiteSlice";
import { gameProxySelector, mobileGameSelector } from "../../redux/slices/fullPageSlice";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { setShowDesktopSignupModal } from "../../redux/slices/desktopSignupSlice";
import PrintSaveUi from "../global/PrintSaveUi";
import PrintSaveErrorModal from "../global/PrintSaveErrorModal";
import { isCrawler, isPrerender, mobile } from "../../utilities/detection";
import MobileAppDownloadModal from "../global/MobileAppDownloadModal";
import DesktopSignupModal from "../subscribe/DesktopSignupModal";
import { getLangCode, language } from "../../config";
import Adhesion from "../ads/Adhesion";
import { mpPageView } from "../../utilities/mixpanel";

export default function Main() {
  const location = useLocation();

  const pathname = useRef(null);
  const search = useRef(null);
  const pageViewCount = useRef(0);

  const isLoggedIn = useSelector(isLoggedInSelector);
  const showAlertModal = useSelector(showAlertModalSelector);
  const showLeaveModal = useSelector(showLeaveModalSelector);
  const url = useSelector(urlSelector);
  const mobileGame = useSelector(mobileGameSelector);
  const gameProxy = useSelector(gameProxySelector);
  const dispatch = useDispatch();

  const [showMobileAppDownloadModal, setShowMobileAppDownloadModal] = useState(false);

  useTimer();
  useFullPage();

  useEffect(() => {
    if (pathname.current !== location.pathname || search.current !== location.search) {
      gtmPageView(`${location.pathname}${location.search}`);
      mpPageView(isLoggedIn);
      pathname.current = location.pathname;
      search.current = location.search;

      const isGameProxyRoute = location.pathname.startsWith(removeTrailingSlash(routeCodes.GAMEPROXY));

      const isLoginRoute = location.pathname.startsWith(removeTrailingSlash(routeCodes.LOGIN));

      const pageViewIncrement = isGameProxyRoute || isLoginRoute ? 0 : 1;

      try {
        let sessionPageViewCount = sessionStorage.getItem("pageviewcount") || 0;
        sessionPageViewCount = Number(sessionPageViewCount) + pageViewIncrement;
        sessionStorage.setItem("pageviewcount", sessionPageViewCount);
        pageViewCount.current = sessionPageViewCount;
      } catch (error) {
        console.log("no session storage available");
        pageViewCount.current = pageViewCount.current + pageViewIncrement;
      }

      if (pageViewCount.current === 2) {
        setShowMobileAppDownloadModal(
          mobile &&
            freePublicUser() &&
            !isCrawler &&
            !isPrerender &&
            !isGameProxyRoute &&
            !isLoginRoute &&
            getLangCode() === language.default,
        );

        dispatch(
          setShowDesktopSignupModal({
            showDesktopSignupModal:
              !mobile && !isLoggedIn && !isCrawler && !isPrerender && !isGameProxyRoute && !isLoginRoute,
          }),
        );
      } else {
        setShowMobileAppDownloadModal(false);
        dispatch(
          setShowDesktopSignupModal({
            showDesktopSignupModal: false,
          }),
        );
      }
    }
  }, [location]);

  return (
    <MessageHandler>
      {metaMain()}
      <Routes />
      <ServiceDownWarningModal />
      <OptionallyDisplayed doDisplay={!mobileGame && !gameProxy}>
        <Footer />
        <Header />

        <UpgradePremiumModal />
        <LoginModal />
        <RevalidateModal />
        <PrintSaveErrorModal />
      </OptionallyDisplayed>
      <OptionallyDisplayed doDisplay={!gameProxy}>
        <OKModal
          doShow={showAlertModal}
          close={() => {
            dispatch(alertToggle());
          }}
          title={getString("tools.timer.alert.0")}
          button={getString("dismiss.0")}
        />

        <CookieBanner />

        <MobileAppDownloadModal doShow={showMobileAppDownloadModal} setShow={setShowMobileAppDownloadModal} />

        <DesktopSignupModal />

        <AdblockModalMessage />

        <AdblockIframe />

        <Adhesion />
      </OptionallyDisplayed>
      <PrintSaveUi />
      <LeavingSiteModal
        doShow={showLeaveModal}
        cancelFunction={() => {
          dispatch(setShowLeaveModal({ showLeaveModal: false }));
        }}
        url={url}
      />
    </MessageHandler>
  );
}
