import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "./modal";
import Confirm from "./confirm";
import { getString } from "../../utilities";
import { getWithExpiry, setWithExpiry } from "../../utilities/localStorage";
import { isCrawler, isPrerender } from "../../utilities/detection";
import { routeCodes } from "../../config/routes";
import { adsSelector } from "../../redux/slices/loginSlice";
import { adBlockingSelector } from "../../redux/slices/userClientSlice";
import { mpModalShown } from "../../utilities/mixpanel";

export default function AdblockModalMessage() {
  const [abmShow, setAbmShow] = useState(false);
  const adBlocking = useSelector(adBlockingSelector);
  const ads = useSelector(adsSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const adBlockModalKey = "abcya_abm_viewed";
  const login = routeCodes.LOGIN.split("/")[1] === location.pathname.split("/")[1];

  const abmSetWithExpiry = () => {
    setWithExpiry(adBlockModalKey, "1", 86400000); // 24 hours
  };

  const abmGetWithExpiry = () => {
    const adBlockModalValue = getWithExpiry(adBlockModalKey);

    const show = !login && ads && adBlocking && !adBlockModalValue;

    if (show !== abmShow) setAbmShow(show);
  };

  useEffect(() => {
    abmGetWithExpiry();
  });

  const modalShownEventData = {
    modalType: "confirm-adblock",
    triggerType: "page load",
    header: getString("adblock.modal.title"),
    primaryCta: getString("join.2"),
    secondaryCta: getString("navigation.login.3"),
    tertiaryCta: "none",
    isDismissible: true,
  };

  if (!isCrawler && !isPrerender) {
    return (
      <Modal doShow={abmShow}>
        <Confirm
          confirm={() => {
            // Mixpanel event
            mpModalShown({
              ...modalShownEventData,
              interaction: "primary cta",
            });
            // set the key/value
            abmSetWithExpiry();
            // go to subscribe page
            navigate(routeCodes.SUBSCRIBE);
          }}
          cancel={() => {
            // set the key/value
            abmSetWithExpiry();
            // should now find cookie and close message
            abmGetWithExpiry();
            // Mixpanel event
            mpModalShown({
              ...modalShownEventData,
              interaction: "dismissed",
            });
          }}
          button={getString("join.2")}
          cancelButton={getString("navigation.close.0")}
        >
          <div className="modal-content-header">{getString("adblock.modal.title")}</div>
          {getString("adblock.modal.message", { html: true })}
        </Confirm>
      </Modal>
    );
  }
  return null;
}
