/* eslint-disable camelcase */
import mixpanel from "mixpanel-browser";
import { adData, getLangCode, mixpanelProjectToken, userRoles } from "../config/index";
import { mobile } from "./detection";
import { language } from "../config";
import routeCodes from "../config/routeCodes";

// Initialize Mixpanel
export const initMixpanel = () => {
  mixpanel.init(mixpanelProjectToken, {
    debug: process.env.NODE_ENV !== "production",
    persistence: "localStorage",
  });

  // Add Super Properties for Mixpanel, which are shared by all events. Some of these may be updated later.
  mixpanel.register({
    is_mobile: mobile,
    ui_lang: getLangCode() === language.default ? language.default : "es",
  });
};

// Mapping from ABcya userType selected during onboarding to Mixpanel user_role
export const getMixpanelUserRole = (userType) => {
  switch (userType) {
    case "STUDENT":
      return "student";
    case "TEACHER":
      return "teacher";
    case "PARENT":
      return "parent";
    default:
      return "anonymous";
  }
};

// Mapping from ABcya planGroup to Mixpanel membership_type
export const getMixpanelMembershipType = (planGroup) => {
  switch (planGroup) {
    case userRoles.free:
      return "free";
    case userRoles.classroom:
      return "premium classroom";
    case userRoles.school:
      return "premium school";
    case userRoles.family:
      return "premium family";
    default:
      return "none";
  }
};

// Count ad elements on the page
const countAdElements = () => {
  let totalCount = 0;

  const hasContent = (elementId) => {
    const element = document.querySelector(`#${elementId}`);
    return element && element.innerHTML.trim() !== "";
  };

  // Count desktop and mobile leader ads
  totalCount += [...adData.divIds.leader.desktop, ...adData.divIds.leader.mobile].reduce(
    (sum, position) => sum + (hasContent(position) ? 1 : 0),
    0,
  );
  // Count sky ads
  totalCount += Object.values(adData.divIds.sky).reduce((sum, position) => sum + (hasContent(position) ? 1 : 0), 0);
  // Count billboard ads
  totalCount += hasContent(adData.divIds.billboard) ? 1 : 0;
  // Count adhesion ads
  totalCount += hasContent(adData.divIds.adhesion) ? 1 : 0;
  // Count video ads
  totalCount += Object.values(adData.divIds.video).reduce((sum, position) => sum + (hasContent(position) ? 1 : 0), 0);

  return totalCount;
};

const billingFrequency = (months) => {
  switch (parseInt(months)) {
    case 1:
      return "monthly";
    case 6:
      return "semiannually";
    case 12:
      return "annually";
    default:
      return "unknown";
  }
};

// Track page view event
export const mpPageView = (isLoggedIn) => {
  try {
    // Track total pageviews using localStorage
    let totalPageviews = localStorage.getItem("total_pageviews");
    if (!totalPageviews) {
      localStorage.setItem("total_pageviews", 1);
      totalPageviews = 1;
    } else {
      localStorage.setItem("total_pageviews", String(Number(totalPageviews) + 1));
      totalPageviews = Number(totalPageviews) + 1;
    }

    // Check if pageviewcount exists in sessionStorage. (pageviewcount is set by Mixpanel on the first pageview.)
    let sessionPageviews = sessionStorage.getItem("pageviewcount");
    if (!sessionPageviews) {
      sessionPageviews = 1;
    } else {
      sessionPageviews = Number(sessionPageviews) + 1;
    }

    // Set some delay to wait for ads to load
    setTimeout(() => {
      const numOfAds = countAdElements();
      mixpanel.track_pageview({
        num_ads_shown: numOfAds,
        session_pageviews: sessionPageviews,
        total_pageviews: totalPageviews,
      });

      if (isLoggedIn) {
        mixpanel.people.set_once({
          ads_shown: 0,
        });
        mixpanel.people.increment("ads_shown", numOfAds);
      }
    }, 2000);
  } catch (e) {}
};

export const mpUserIdentify = (userId) => {
  mixpanel.identify(userId);
};

export const mpSetUserProperties = (properties) => {
  mixpanel.people.set(properties);
};

export const mpUserIdentifyAndSetProperties = (userId, properties) => {
  mixpanel.identify(userId);
  mixpanel.people.set(properties);
};

export const mpUserReset = () => {
  mixpanel.reset();
};

export const mpRegisterEventProperties = (properties) => {
  mixpanel.register(properties);
};

export const mpLoginSuccess = (tokenRole, loginCount, isLoginByForm, userType, planGroup, userId, createdAt) => {
  try {
    const mpUserRole = getMixpanelUserRole(userType);
    const mpMembershipType = getMixpanelMembershipType(planGroup);
    mpUserIdentifyAndSetProperties(userId, {
      signup_date: createdAt,
      signup_language: getLangCode() === language.default ? language.default : "es",
      user_role: tokenRole === userRoles.student ? "student" : mpUserRole,
      membership_type: mpMembershipType,
      last_active_date: new Date().toISOString(),
    });
    mpRegisterEventProperties({
      user_role: tokenRole === userRoles.student ? "student" : mpUserRole,
      membership_type: mpMembershipType,
    });

    const isNewSignup = window.localStorage.getItem("isNewSignup") === "1";
    window.localStorage.removeItem("isNewSignup"); // remove it after checking
    let loginMethod;
    let loginPagePath;
    let loginDisplayType;
    // manual login vs token-based login
    if (isLoginByForm) {
      loginMethod = tokenRole === "STUDENT" ? "access code" : "password";
      // new signup vs returning user
      if (isNewSignup) {
        switch (tokenRole) {
          case userRoles.free:
            loginPagePath = routeCodes.SUBSCRIBE_FREE;
            break;
          case userRoles.family:
            loginPagePath = routeCodes.SUBSCRIBE_FAMILY;
            break;
          case userRoles.classroom:
            loginPagePath = routeCodes.SUBSCRIBE_CLASSROOM;
            break;
          case userRoles.school:
            loginPagePath = routeCodes.SUBSCRIBE_ABCYA_SCHOOL;
            break;
          default:
            loginPagePath = "/";
        }
        loginDisplayType = "page";
      } else {
        loginPagePath = window.location.pathname;
        loginDisplayType = "modal";
      }
    } else {
      loginMethod = "token-based";
      loginPagePath = window.location.pathname;
      loginDisplayType = "auto-login";
    }

    mixpanel.track("Login Success", {
      login_origin_url: window.location.origin + loginPagePath,
      page_url_path: loginPagePath,
      login_display_type: loginDisplayType,
      login_count: loginCount,
      login_method: loginMethod,
    });
    mixpanel.people.set_once({
      total_sessions: 0,
    });
    mixpanel.people.increment("total_sessions", 1);
  } catch (e) {}
};

export const mpPaymentSubmitted = (subData) => {
  try {
    const months = parseInt(subData.months.slice(1)); // "m1" -> 1, "m6" -> 6, "m12" -> 12

    mixpanel.track("Payment Submitted", {
      subscription_origin_url: window.location.href,
      page_url_path: window.location.pathname,
      subscription_plan_type: getMixpanelMembershipType(subData.planGroup),
      subscription_billing_frequency: billingFrequency(months),
      promo_code_used: subData.isPromoCodeUsed,
      gift_card_used: subData.isGiftCardUsed,
      payment_amount: subData.finalAmount,
      price: subData.amount,
    });
  } catch (e) {}
};

export const mpModalShown = (modalData) => {
  try {
    mixpanel.track("Modal Shown", {
      page_title: document.title,
      modal_type: modalData.modalType || "",
      modal_trigger_type: modalData.triggerType || "",
      modal_header: modalData.header || "",
      modal_primary_cta: modalData.primaryCta || "",
      modal_secondary_cta: modalData.secondaryCta || "",
      modal_tertiary_cta: modalData.tertiaryCta || "",
      modal_is_dismissible: modalData.isDismissible || false,
      modal_interaction: modalData.interaction || "",
    });
  } catch (e) {}
};

export const mpSignUpSuccess = () => {
  try {
    window.localStorage.setItem("isNewSignup", "1"); // used for Login Success event to check if it's a new signup
    mixpanel.track("Signup Success", {
      signup_origin_url: window.location.href,
      page_url_path: window.location.pathname,
    });
    mixpanel.people.set({
      signup_date: new Date().toISOString(),
      signup_language: getLangCode(),
    });
  } catch (e) {
    console.log("Error in mpSignUpSuccess: ", e);
  }
};

export const mpSubCTASelect = (feature, ctaLocation, ctaText) => {
  try {
    mixpanel.track("Subscription CTA Selected", {
      page_title: document.title,
      locked_feature: feature,
      subscription_cta_location: ctaLocation,
      subscription_cta_text: ctaText,
    });
    mixpanel.register({
      last_subscription_cta_location: ctaLocation,
      last_subscription_cta_text: ctaText,
    });
  } catch (e) {
    console.log("Error in mpSubCTASelect: ", e);
  }
};
