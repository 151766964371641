import React, { useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { mobile } from "../../utilities/detection";
import { AdContext } from "../../context/adContext";
import AdFreeLink from "./adFreeLink";
import { useWidthSize } from "../../hooks";
import { adData } from "../../config";
import { adsSelector } from "../../redux/slices/loginSlice";

export default function Leader({ sizeArray = [], className, adId }) {
  const adDiv = useRef(null);
  const { leaderDivs } = useContext(AdContext);
  const ads = useSelector(adsSelector);
  const advertId = adId || adData.divIds.leader[mobile ? "mobile" : "desktop"][0];

  const leaderShow = useWidthSize(sizeArray);

  const handleAdDiv = useCallback((node) => {
    if (node) {
      const leaderObject = leaderDivs[advertId];
      node.appendChild(leaderObject.div);
      if (!leaderObject.created) {
        leaderObject.created = true;
      }
      adDiv.current = leaderObject.div;
    }
  }, []);

  const renderLeader = () => {
    const leaderStyles = [];
    if (className) {
      leaderStyles.push(className);
    }

    let leaderContainerStyle = "a-leader-container";

    if (mobile) {
      leaderStyles.push("a-leader-mobile");
      leaderContainerStyle = "a-leader-container-mobile";
    }

    const leaderStyle = leaderStyles.join(" ");

    return (
      <div data-testid="leader-ad" className={`a-leader ${leaderStyle}`}>
        <div className="a-leader-wrapper">
          <div id={`${advertId}-container`} className={leaderContainerStyle} ref={handleAdDiv} />
          <div className="a-cta">
            <AdFreeLink />
          </div>
        </div>
      </div>
    );
  };

  if (ads && (leaderShow || mobile)) {
    return <>{renderLeader()}</>;
  }

  return null;
}

Leader.propTypes = {
  sizeArray: PropTypes.array,
  className: PropTypes.string.isRequired,
  adId: PropTypes.string,
};
