import { getString } from "../index.js";
import { TEXT_FIELD_CHAR_LIMIT } from "../../config/index.js";

export const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email, field) => {
  return emailReg.test(email.toLowerCase()) || getString("forms.errorMessages.email.0", { replace: [field] });
};

export const validateAlphaNumeric = (fieldName, value) => {
  const re = /^[a-z0-9_-]*$/;
  return re.test(value) || getString("forms.errorMessages.nonAlphaNumericHyphenUnderscore", { replace: [fieldName] });
};

export const validateGiftCard = (value) => {
  const re = /^([a-zA-Z0-9])+$/;
  const myText = value.trim();

  return re.test(myText) && myText.length <= TEXT_FIELD_CHAR_LIMIT;
};

export const getMaxLengthValidator = (fieldName, limit) => ({
  value: limit,
  message: getString("forms.errorMessages.isTooLong", { replace: [fieldName, limit] }),
});

export const getMinLengthValidator = (fieldName, limit) => ({
  value: limit,
  message: getString("forms.errorMessages.isTooShort", { replace: [fieldName, limit] }),
});

export const validateWhitespace = (value, message) => {
  const myText = value ? !!value.trim() : null;
  return myText || message;
};
