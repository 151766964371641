import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { gtmJoinClick } from "../../utilities/gtm";
import { getString, isLoggedInWithoutRole } from "../../utilities";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import { getLangCode, language, userRoles, variousURLs } from "../../config";
import { routeCodes } from "../../config/routes";
import feedbackStrings from "../../strings/feedback";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { leaveSiteHandler } from "../../redux/slices/leavingSiteSlice";
import { mpSubCTASelect } from "../../utilities/mixpanel";

export default function ParteaNav() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const langCode = getLangCode();

  const renderStandardsLink = () => {
    if (langCode !== language.default) {
      return null;
    }
    return (
      <button
        type="button"
        data-testid="standards-link"
        className="button-flat-color pt-nav-standards"
        onClick={() => {
          navigate(routeCodes.STANDARDS);
        }}
      >
        {getString("standards.title.0")}
      </button>
    );
  };

  const renderBlogsLink = () => {
    if (langCode !== language.default) {
      return null;
    }
    return (
      <button
        type="button"
        data-testid="blog-link"
        className="button-flat-color pt-nav-blog"
        onClick={() => {
          navigate(routeCodes.BLOG);
        }}
      >
        {getString("blog.title.1")}
      </button>
    );
  };

  return (
    <div className="pt-nav">
      <OptionallyDisplayed doDisplay={!isLoggedIn}>
        <button
          type="button"
          className={`button-flat-color pt-nav-join ${langCode}`}
          onClick={() => {
            gtmJoinClick(location.pathname, "parents-teachers-nav-button");
            mpSubCTASelect("general", "in content", getString("subscribe.options.0"));
            navigate(routeCodes.SUBSCRIBE);
          }}
        >
          {getString("subscribe.options.0")}
        </button>
      </OptionallyDisplayed>

      <OptionallyDisplayed doDisplay={isLoggedInWithoutRole(userRoles.student)}>
        <button
          type="button"
          className={`button-flat-color pt-nav-manage ${langCode}`}
          onClick={() => {
            navigate(routeCodes.MANAGE);
          }}
        >
          {getString("manage.title.0")}
        </button>
      </OptionallyDisplayed>

      <button
        type="button"
        className={`button-flat-color pt-nav-purchase-gc ${langCode}`}
        onClick={() => {
          navigate(routeCodes.GIFTCARD);
        }}
      >
        {getString("giftcard.purchase.2")}
      </button>

      <button
        type="button"
        className={`button-flat-color pt-nav-redeem-gc ${langCode}`}
        onClick={() => {
          navigate(routeCodes.REDEEM);
        }}
      >
        {getString("giftcard.redeem.1")}
      </button>

      {renderBlogsLink()}

      {renderStandardsLink()}

      <OptionallyDisplayed doDisplay={isLoggedIn}>
        <button
          type="button"
          className={`button-flat-color pt-nav-tools ${langCode}`}
          onClick={() => {
            navigate(routeCodes.TOOLS);
          }}
        >
          {getString("tools.title.0")}
        </button>
      </OptionallyDisplayed>

      <div className="button-unit">
        <button
          type="button"
          className={`button-flat-color pt-nav-about ${langCode}`}
          onClick={() => {
            navigate(routeCodes.ABOUT);
          }}
        >
          {getString("about.title.0")}
        </button>

        <button
          type="button"
          className={`button-flat-color pt-nav-feedback ${langCode}`}
          onClick={() => {
            navigate(routeCodes.FEEDBACK);
          }}
        >
          {getString("title.1", { strings: feedbackStrings })}
        </button>

        <OptionallyDisplayed doDisplay={!isLoggedIn || isLoggedInWithoutRole(userRoles.student)}>
          <button
            type="button"
            className={`button-flat-color pt-nav-store ${langCode}`}
            onClick={() => {
              dispatch(leaveSiteHandler({ url: variousURLs.abcyaStore }));
            }}
          >
            {getString("store.0")}
          </button>
        </OptionallyDisplayed>
      </div>
    </div>
  );
}
