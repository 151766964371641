import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getString } from "../../utilities";
import { getLangCode, userRoles } from "../../config";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import Control from "./control";
import Arrow from "../../../assets/svg/arrowRight.svg";
import Instructions from "./instructions";
import { roleSelector } from "../../redux/slices/loginSlice";
import { isOnSelector, remainingSecondsSelector, setTimer, timerToggle } from "../../redux/slices/timerSlice";

export default function Timer() {
  const isOn = useSelector(isOnSelector);
  const remainingSeconds = useSelector(remainingSecondsSelector);
  const dispatch = useDispatch();
  const role = useSelector(roleSelector);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const renderNumValues = (max) => {
    const returnOptions = [];
    for (let m = 0; m <= max; m++) {
      returnOptions.push(
        <option value={m} key={m + 1}>
          {m}
        </option>,
      );
    }
    return returnOptions;
  };

  const isFree = role === userRoles.free;
  const controlDisabled = isFree;
  const selectDisabled = isFree || isOn;

  return (
    <div id="timer-anchor" className="tools-timer wrapper padded even">
      <h2>{getString("tools.timer.title.1")}</h2>
      <div className="container-small tools-timer-controls">
        <Instructions isFree={isFree} feature="timer tool">
          {getString("tools.timer.instructions")}
        </Instructions>

        <div className="controls">
          <Control
            isActive={!!isOn}
            isDisabled={controlDisabled}
            label={getString("tools.timer.title.1")}
            icon={{ active: <Arrow /> }}
            className={getLangCode()}
            clickFunc={() => {
              dispatch(setTimer({ minutes: parseInt(minutes, 10), hours: parseInt(hours, 10) }));
              dispatch(timerToggle());
            }}
          />

          <div className="control">
            <label htmlFor="hours">{getString("tools.timer.hours")}</label>
            <select
              id="hours"
              disabled={selectDisabled}
              className="tools-timer-select"
              value={hours}
              onChange={(e) => {
                setHours(e.target.value);
              }}
              data-testid="hours-select"
            >
              {renderNumValues(10)}
            </select>
          </div>

          <div className="control">
            <label htmlFor="minutes">{getString("tools.timer.minutes")}</label>
            <select
              id="minutes"
              disabled={selectDisabled}
              className="tools-timer-select"
              value={minutes}
              onChange={(e) => {
                setMinutes(e.target.value);
              }}
              data-testid="minutes-select"
            >
              {renderNumValues(59)}
            </select>
          </div>
        </div>
      </div>

      <OptionallyDisplayed doDisplay={remainingSeconds > 0}>
        <div className="tools-timer-countdown">
          {getString("tools.timer.remaining", {
            replace: [
              Math.floor(remainingSeconds / 3600),
              Math.floor((remainingSeconds % 3600) / 60),
              remainingSeconds % 60,
            ],
          })}
        </div>
      </OptionallyDisplayed>
    </div>
  );
}
