import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "urql";

import { metaDefault } from "../../config/meta";
import { CHECK_GIFTCARD_QUERY, RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString } from "../../utilities";
import { routeCodes } from "../../config/routes";
import { recaptchaSiteKey } from "../../config";
import TextViewHook from "../global/textViewHook";
import { validateGiftCard } from "../../utilities/forms/rules";
import { useLazyQuery, useQueryHandlers } from "../../hooks";

export default function Redeem() {
  const action = `Check_GiftCard_Form_Submission`;
  const [querying, setQuerying] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [giftCard, setGiftCard] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const { formState, handleSubmit, register, getValues } = useForm({
    mode: "onChange",
    defaultValues: { giftCard: "" },
  });

  const applyGiftCard = (data) => {
    if (data && data.checkGiftCard) {
      const { checkGiftCard } = data;

      if (checkGiftCard.redeemed) {
        // already redeemed
        // supply error message {message: "my message"}
        setSubmitErrors({ message: getString("giftcard.errors.redeemed") });
        setQuerying(false);
      } else {
        // not redeemed - perform duties
        setQuerying(false);

        navigate(routeCodes.REDEEMCARD, { state: { checkGiftCard } });
      }
    } else {
      // came back null (no such giftcard in database)
      // supply error message
      setSubmitErrors({ message: getString("giftcard.errors.invalid") });
      setQuerying(false);
    }
  };

  const onError = (error) => {
    setQuerying(false);
    setSubmitting(false);
    setSubmitErrors(error);
  };

  const [result] = useQuery({
    query: CHECK_GIFTCARD_QUERY,
    variables: { id: giftCard },
    pause: !giftCard,
  });
  useQueryHandlers(result, (data) => applyGiftCard(data), onError);

  const checkGiftcardHandler = () => {
    if (!querying) {
      setSubmitting(false);
      setQuerying(true);
      setSubmitErrors(null);

      const giftCard = getValues("giftCard");
      setGiftCard(giftCard);
    }
  };

  const onCompleted = (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      checkGiftcardHandler();
    } else {
      // not verified
      setSubmitting(false);
      setSubmitErrors({ message: reCaptchaVerify.message });
    }
  };

  const { executeQuery: recaptcha, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onCompleted,
    onError,
  );

  const recaptchaVerify = () => {
    if (!submitting) {
      setSubmitting(true);

      // perform reCaptcha to get token
      window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

        if (token) {
          recaptcha({ token, action, verify: false, siteKey: recaptchaSiteKey });
        }
      });
    }
  };

  const renderContent = () => {
    if (recaptchaLoading || querying || submitting) {
      return (
        <LoadingAnim>
          <p>{getString("giftcard.checking")}</p>
        </LoadingAnim>
      );
    }

    return (
      <div className="container redeem-code-container">
        <h2>{getString("redeem.code.1")}</h2>

        <form onSubmit={handleSubmit(recaptchaVerify)}>
          <FormProvider register={register}>
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <TextViewHook
              rules={{
                required: getString("forms.errorMessages.isRequired.0", { replace: [getString("redeem.code.0")] }),
                validate: (value) =>
                  value.length === 0 || validateGiftCard(value) || getString("giftcard.errors.invalid"),
              }}
              name="giftCard"
              label={getString("redeem.code.0")}
              placeholder={getString("giftcard.claim.example")}
              errors={{}}
            />

            <p>
              <button type="submit" disabled={!formState.isValid} className="button-flat-color green">
                {getString("giftcard.redeem.2")}
              </button>
            </p>

            <button
              type="button"
              className="button-flat-color pt-yellow"
              onClick={() => {
                navigate(routeCodes.GIFTCARD);
              }}
            >
              {getString("giftcard.purchase.0")}
            </button>

            <div className="form-terms">{getString("recaptcha", { html: true })}</div>
          </FormProvider>
        </form>
      </div>
    );
  };

  return (
    <>
      {metaDefault({ path: location.pathname, title: getString("redeem.title.0") })}

      <div className="redeem">
        <div className="wrapper padded">
          <div className="container">
            <h1>{getString("redeem.title.0")}</h1>
          </div>
        </div>

        <div className="wrapper padded">{renderContent()}</div>
      </div>
    </>
  );
}
