import React, { useEffect, useState } from "react";
import { useCombobox } from "downshift";
import PropTypes from "prop-types";
import { getString, getSuggestions } from "../../utilities";
import ChevronDown from "../../../assets/svg/chevron-down.svg";
import ChevronUp from "../../../assets/svg/chevron-up.svg";

export default function Select({
  error,
  items,
  label,
  onChange,
  placeholder = getString("forms.select.0"),
  searchKey,
  defaultValue,
}) {
  // Preprocess items to add a unique id to each item.
  const processedItems = items.map((item, index) => {
    return { ...item, id: `${item[searchKey]}-${index}` };
  });

  const [suggestions, setSuggestions] = useState(processedItems);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    setHighlightedIndex,
    closeMenu,
  } = useCombobox({
    items: suggestions,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
      closeMenu();
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue.length === 0) {
        setSuggestions(processedItems);
      } else {
        const newSuggestions = getSuggestions(inputValue, processedItems, searchKey);
        setSuggestions(newSuggestions);
      }
    },
    itemToString: (item) => (item ? item[searchKey] : ""),
    initialSelectedItem: defaultValue,
  });

  useEffect(() => {
    setSuggestions(processedItems);
  }, [items]);

  useEffect(() => {
    if (highlightedIndex <= -1) {
      setHighlightedIndex(0);
    }
  }, [isOpen, suggestions]);

  const renderChevron = () => {
    if (isOpen) {
      return <ChevronUp />;
    }
    return <ChevronDown />;
  };

  const renderLabel = () => {
    if (label) {
      return (
        <div className="static-label" {...getLabelProps()}>
          {label}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {renderLabel()}
      <div className="form-field form-field-select">
        <div>
          <input placeholder={placeholder} {...getInputProps()} />
        </div>
        <ul {...getMenuProps()} className="select-suggestions-container suggestions-list">
          {isOpen &&
            suggestions.map((item, index) => {
              return (
                <li
                  className={`suggestion${highlightedIndex === index ? " highlighted" : ""}`}
                  key={item.id}
                  {...getItemProps({ item, index })}
                >
                  {item[searchKey]}
                </li>
              );
            })}
        </ul>
        <button type="button" className="select-toggle" {...getToggleButtonProps()}>
          {renderChevron()}
        </button>
        {error && <div className="validation-error">{error.message}</div>}
      </div>
    </>
  );
}

Select.propTypes = {
  // Error from react-hook-form controller
  error: PropTypes.object,
  // Items to draw suggestions from.
  items: PropTypes.array.isRequired,
  // Input label
  label: PropTypes.string,
  // onChange handler passed by React Hook From controller, which determines the form value provided by this component.
  onChange: PropTypes.func.isRequired,
  // Placeholder text for select field.
  placeholder: PropTypes.string,
  // Item object key to find and display suggestions.
  searchKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
};
