import React, { useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import ModalClose from "../global/modalClose";
import { getString, handlePostRequest, login } from "../../utilities";
import { gtmSignUp, gtmSubscribeNow } from "../../utilities/gtm";
import { modalToggle } from "../../redux/slices/loginSlice";
import GameEmail from "../../../assets/img/gameEmail.svg";
import UnlockPrintables from "../../../assets/img/unlockPrintables.svg";
import TextViewHook from "../global/textViewHook";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import {
  EMAIL_CHAR_LIMIT,
  getLangCode,
  language,
  PASSWORD_CHAR_LIMIT,
  PASSWORD_CHAR_MINIMUM,
  recaptchaSiteKey,
  userRoles,
} from "../../config";
import { RECAPTCHA_VERIFY_QUERY, SUBSCRIPTIONS_PLAN_QUERY } from "../../graphql/queries";
import { useMutation } from "urql";
import { useLazyQuery } from "../../hooks";
import { SIGNUP_MUTATION } from "../../graphql/mutations";
import ErrorMessage from "../global/errorMessage";
import { getMaxLengthValidator, getMinLengthValidator, validateEmail } from "../../utilities/forms/rules";
import { setShowDesktopSignupModal, setCanEscape } from "../../redux/slices/desktopSignupSlice";
import AgeGateForm from "./AgeGateForm";
import LoadingAnim from "../global/loadingAnim";
import routeCodes from "../../config/routeCodes";
import { mpSignUpSuccess } from "../../utilities/mixpanel";
import { mpModalShown } from "../../utilities/mixpanel";

export default function DesktopSignupForm() {
  const langCode = getLangCode();
  const loginLinkId = "desktop-signup-login";
  const action = `FREE_User_Desktop_Signup_Form_Submission`;

  const dispatch = useDispatch();

  const planRef = useRef({});

  const [doShowAgeGate, setDoShowAgeGate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);

  const modalShownEventData = {
    modalType: "signup-form",
    triggerType: "page load",
    header: getString("subscribe.desktopSignup.title"),
    primaryCta: getString("subscribe.desktopSignup.cta"),
    secondaryCta: getString("navigation.login.3"),
    tertiaryCta: "none",
    isDismissible: true,
  };

  const errorsSetEscape = (e) => {
    setSubmitErrors(e);

    dispatch(
      setCanEscape({
        canEscape: true,
      }),
    );
  };

  const getVariables = () => {
    const { email, password } = getValues();

    const myVariables = {
      email: email.trim(),
      emailVerify: email.trim(),
      password,
      passwordVerify: password,
      billingPlan: planRef.current.planId,
    };

    return myVariables;
  };

  const closeModal = () => {
    mpModalShown({
      ...modalShownEventData,
      interaction: "dismissed",
    });
    dispatch(
      setShowDesktopSignupModal({
        showDesktopSignupModal: false,
      }),
    );
  };

  const onSignupCompleted = (data) => {
    const {
      signup: { id, subscription, token },
    } = data;

    const { planId } = planRef.current;

    // Event tracking
    gtmSignUp(id, { module: "desktop signup modal" });
    mpSignUpSuccess();

    try {
      window.localStorage.setItem("planId", planId);
      window.localStorage.setItem("billingPlatform", subscription.billingPlatform);
    } catch (e) {}
    closeModal();
    dispatch(
      setCanEscape({
        canEscape: true,
      }),
    );
    login({ token, path: routeCodes.ONBOARDING });
  };

  const [signupResult, signup] = useMutation(SIGNUP_MUTATION);
  const { fetching: signupLoading } = signupResult;

  const onSubscriptionsCompleted = (data) => {
    if (data && data.subscriptions && data.subscriptions.length > 0) {
      planRef.current = data.subscriptions[0];

      const variables = getVariables();
      const { name, planId, amount } = planRef.current;
      gtmSubscribeNow({ planId, amount, name });
      signup(variables).then((result) =>
        handlePostRequest(result, onSignupCompleted, (error) => {
          errorsSetEscape(error);
        }),
      );
    }
  };

  const { executeQuery: subscriptionsPlanQuery, loading: subscriptionsLoading } = useLazyQuery(
    SUBSCRIPTIONS_PLAN_QUERY,
    onSubscriptionsCompleted,
    (error) => {
      errorsSetEscape(error);
    },
  );

  const onRecaptchaCompleted = (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      subscriptionsPlanQuery({ planGroup: userRoles.free });
    } else {
      // not verified
      errorsSetEscape({ message: reCaptchaVerify.message });
    }
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    (error) => {
      errorsSetEscape(error);
    },
  );

  const onChange = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleLoginRef = useCallback((node) => {
    if (node) {
      document.getElementById(loginLinkId).addEventListener(
        "click",
        () => {
          mpModalShown({
            ...modalShownEventData,
            interaction: "secondary cta",
          });
          closeModal();
          dispatch(modalToggle());
        },
        { once: true },
      );
    }
  }, []);

  const loading = signupLoading || recaptchaLoading || subscriptionsLoading || submitting;

  let headerTitle = getString("subscribe.desktopSignup.title");

  if (doShowAgeGate) {
    headerTitle = getString("ageGate.title");
  }

  if (loading) {
    headerTitle = getString("subscribe.loading");
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingAnim />;
    }

    if (doShowAgeGate) {
      return (
        <AgeGateForm
          closeFunction={() => {
            setDoShowAgeGate(false);
          }}
          submitFunction={() => {
            // perform reCaptcha to get token
            setSubmitting(true);

            dispatch(
              setCanEscape({
                canEscape: false,
              }),
            );

            window.grecaptcha.enterprise.ready(() => {
              window.grecaptcha.enterprise
                .execute(recaptchaSiteKey, { action })
                .then((token) => {
                  setSubmitting(false);
                  recaptchaVerify({ token, action, verify: true, siteKey: recaptchaSiteKey });
                })
                .catch((e) => {
                  errorsSetEscape(e);
                  setSubmitting(false);
                });
            });
          }}
        />
      );
    }

    return (
      <>
        <div className="benefits-container">
          <div className="benefit">
            <img
              width="176.98"
              height="133.53"
              src={GameEmail}
              alt={getString("subscribe.desktopSignup.email")}
              className="benefit-art"
            />
            <div className="benefit-caption">{getString("subscribe.desktopSignup.email")}</div>
          </div>

          <div className="benefit">
            <img
              width="150.8556"
              height="161.09"
              src={UnlockPrintables}
              alt={getString("subscribe.desktopSignup.printable")}
              className="benefit-art"
            />
            <div className="benefit-caption">{getString("subscribe.desktopSignup.printable")}</div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(() => {
            if (!loading) {
              setDoShowAgeGate((prev) => !prev);
            }
          })}
        >
          <FormProvider register={register}>
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <TextViewHook
              rules={{
                required: getString(
                  langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
                  { replace: [getString(langCode === language.la ? "forms.email.5" : "forms.email.4")] },
                ),
                validate: (value) =>
                  validateEmail(value, getString(langCode === language.la ? "forms.email.7" : "forms.email.4")),
                maxLength: getMaxLengthValidator(getString("forms.email.4"), EMAIL_CHAR_LIMIT),
              }}
              errors={errors}
              name="email"
              placeholder={getString("forms.email.4")}
              label={getString("forms.email.4")}
              onChange={onChange}
            />

            <TextViewHook
              rules={{
                required: getString(
                  langCode === language.la ? "forms.errorMessages.isRequired.1" : "forms.errorMessages.isRequired.0",
                  { replace: [getString(langCode === language.la ? "forms.password.3" : "forms.password.0")] },
                ),
                maxLength: getMaxLengthValidator(
                  getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
                  PASSWORD_CHAR_LIMIT,
                ),
                minLength: getMinLengthValidator(
                  getString(langCode === language.la ? "forms.password.4" : "forms.password.0"),
                  PASSWORD_CHAR_MINIMUM,
                ),
              }}
              errors={errors}
              name="password"
              type="password"
              placeholder={getString("forms.password.2")}
              label={getString("forms.password.2")}
              onChange={onChange}
            />

            <button
              type="submit"
              disabled={loading}
              className="button-flat-color pt-green"
              onClick={() => {
                mpModalShown({
                  ...modalShownEventData,
                  interaction: "primary cta",
                });
              }}
            >
              {getString("subscribe.desktopSignup.cta")}
            </button>
          </FormProvider>
        </form>
      </>
    );
  };

  return (
    <>
      {!loading && <ModalClose close={closeModal} />}

      <div className="desktop-signup-modal-container">
        <div className="modal-content-header">{headerTitle}</div>

        {renderContent()}

        {!loading && (
          <div data-testid="desktop-signup-form-footer" className="modal-content-footer">
            <div className="login-container" ref={handleLoginRef}>
              {getString("subscribe.desktopSignup.login", { replace: [loginLinkId], html: true })}
            </div>

            <div className="subscribe-terms">
              <p>{getString("subscribe.terms", { replace: [getString("subscribe.desktopSignup.cta")], html: true })}</p>
              <p>{getString("recaptcha", { html: true })}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
