import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation, useOutletContext } from "react-router-dom";

import { metaDefault } from "../../config/meta";
import { getLangCode, language, recaptchaSiteKey } from "../../config";
import { SIGNUP_MUTATION } from "../../graphql/mutations";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest, login } from "../../utilities";
import { gtmSubscribeNow } from "../../utilities/gtm";
import AgeGateModal from "./ageGateModal";
import AccountFields from "./accountFields";
import { submitError } from "../../utilities/subscribe";
import { useLazyQuery } from "../../hooks";
import { useMutation } from "urql";
import routeCodes from "../../config/routeCodes";

export default function FreeForm() {
  const action = `FREE_User_Form_Submission`;
  // const [plan, setPlan] = useState({});
  const [ageGateModal, setAgeGateModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);

  const { success, submitting, setSubmitting, dataFetching, dataError, freeData: planData } = useOutletContext();

  const location = useLocation();

  const {
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const getVariables = () => {
    const { email, emailVerify, password, passwordVerify } = getValues();

    const myVariables = {
      email: email.trim(),
      emailVerify: emailVerify.trim(),
      password,
      passwordVerify,
      billingPlan: planData?.planId,
    };

    return myVariables;
  };

  const onSignupCompleted = (data) => {
    const token = success(data, { planId: planData?.planId, amount: planData?.amount, name: planData?.name });
    login({ token, path: routeCodes.ONBOARDING });
  };

  const onSignupError = (error) => {
    setSubmitting(false);
    setSubmitErrors(error);
  };

  const [signupResult, signup] = useMutation(SIGNUP_MUTATION);
  const { fetching: loading } = signupResult;

  const onRecaptchaCompleted = async (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      await gtmSubscribeNow({ planId: planData?.planId, amount: planData?.amount, name: planData?.name });
      signup(getVariables()).then((result) => handlePostRequest(result, onSignupCompleted, onSignupError));
    } else {
      // not verified
      setSubmitting(false);
      setSubmitErrors({ message: reCaptchaVerify.message });
    }
  };

  const onRecaptchaError = (error) => {
    submitError(error, setSubmitErrors, setSubmitting);
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  const disabled = submitting || !planData || dataFetching || dataError;

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: `${getString("join.3")} • ${getString("subscribe.plans.free.title.1")}`,
      })}
      <div className="wrapper padded odd">
        <div className="container-small">
          <form
            onSubmit={handleSubmit(() => {
              if (!submitting) {
                setAgeGateModal((prev) => !prev);
              }
            })}
            disabled={disabled}
            className={disabled && "disabled"}
          >
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <h2>{getString("subscribe.create")}</h2>

            <AccountFields
              errors={errors}
              submitErrors={submitErrors}
              setSubmitErrors={setSubmitErrors}
              isDisabled={disabled}
            />

            <button type="submit" className="button-flat-color pt-green" disabled={disabled}>
              {getString("subscribe.submit")}
            </button>
          </form>

          <div className="subscribe-terms">
            <p>
              {getString("subscribe.terms", {
                replace: [
                  getLangCode() === language.la ? getString("subscribe.title.1") : getString("subscribe.title.0"),
                ],
                html: true,
              })}
            </p>
            <p>{getString("recaptcha", { html: true })}</p>
          </div>
        </div>
      </div>
      <AgeGateModal
        showAgeGateModal={ageGateModal}
        setAgeGateModal={setAgeGateModal}
        submit={() => {
          setSubmitting(true);

          // perform reCaptcha to get token
          window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(recaptchaSiteKey, { action });

            if (token) {
              recaptchaVerify({ token, action, verify: true, siteKey: recaptchaSiteKey });
            }
          });
        }}
      />
      {(loading || recaptchaLoading || submitting) && (
        <LoadingAnim position="fixed" className="background-transparent-white">
          <h4>{getString("subscribe.loading")}</h4>
        </LoadingAnim>
      )}
    </>
  );
}
