import React, { useContext, useCallback, useRef } from "react";
import { AdContext } from "../../context/adContext";
import AdFreeLink from "./adFreeLink";
import { useWidthSize } from "../../hooks";
import { adData } from "../../config";

export default function Billboard() {
  const adDiv = useRef(null);
  const billShow = useWidthSize(["lg", "adsLG", "xl", "xxl"]);
  const { billboardDiv, billboardCreated } = useContext(AdContext);

  const adId = adData.divIds.billboard;

  const handleAdDiv = useCallback((node) => {
    if (node) {
      adDiv.current = billboardDiv;
      node.appendChild(billboardDiv);

      if (!billboardCreated.current) {
        billboardCreated.current = true;
      }
    }
  }, []);

  if (billShow) {
    // only shown if screen is wider than "lg(1024px)"
    //  see config breakpoints

    return (
      <>
        <div id={`${adId}-container`} data-testid="billboard-ad" className="a-billboard-container" ref={handleAdDiv} />
        <div className="a-cta">
          <AdFreeLink />
        </div>
      </>
    );
  }

  return null;
}
